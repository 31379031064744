export const apiEndPoints = {
    HOME : 'page/home-page',
    ABOUT : 'page/about-page',
    LANDOWNER : 'page/landowner-page',
    CONTACT : 'page/contact-page',
    MEDIA : 'page/media-page',
    MEDIADETAIL : 'media',
    PROJECT : 'page/projects',
    PROJECTDETAIL : 'project',
    PROJECTS_BY_FILTER: 'getProjects/all',
    CONTACT_FORM:'contact-form-7/v1/contact-forms/7/feedback',
    LANDOWNER_FORM:'contact-form-7/v1/contact-forms/94/feedback',
};
