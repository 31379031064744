import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover, text} from "../../styles/globalStyleVars";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import Overview from "../Overview";
import ReactHtmlParser from "react-html-parser";
import ButtonV2 from "../ButtonV2";

const Timeline = ({data,testimonial}) => {

    let desc=`<h5 class="split-up">RANCON is a major conglomerate in Bangladesh. RANCON develops and manufactures its brands alongside representing global brands. From a journey that began in 1979 with the right to distribute Mitsubishi vehicles in Bangladesh, the RANCON group has diversified from an automobile distributor to a multi-disciplinary business enterprise.
</h5><h5 class="split-up">The group has expanded its business to electronics, real estate, deep-sea fishing, and telecommunications. RANCON has 32 SBUS spanning over seven divisions. The group is working with 14 premium brands and locally developed own brands.</h5>
`


    return (
        <>
            <StyledTimeline className={`timeline ${testimonial?.length>0 ? 'pt-200' : ''} pb-200`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={'d-flex justify-content-center align-items-center mobile'}>
                                <Title text={data?.title} center margin={'0 0 60px 0'} />
                            </div>
                            <div className={'para split-up'}>
                                {ReactHtmlParser(data?.description)}
                            </div>

                        </Col>
                    </Row>

                    <Row>

                        <div className="timeline__wrap">
                            {
                                data?.list && data?.list?.length>0 &&
                                data?.list?.map((element,index)=>{
                                    return(
                                        <div className="timeline__wrap__single">
                                            <div className="timeline__wrap__single__number">
                                                <p>{index+1}</p>
                                            </div>

                                            <div className="timeline__wrap__single__text">
                                                {element?.year && <h4 className={''}>{element?.year}</h4>}
                                                {element?.description && <p className={''}>{element?.description}</p>}
                                            </div>
                                            <div className="timeline__wrap__single__img">
                                                <div
                                                    className={`timeline__wrap__single__img__img overlay-right`}>
                                                    <img
                                                        src={element?.image}
                                                        alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                   </Row>
                </Container>
            </StyledTimeline>
        </>
    );
};

const StyledTimeline = styled.section`
  background-color: #EDEEEF;
  position: relative;
  .para{
    h5{
      text-align: center;
      margin-bottom: 28px;
      &:last-child{
        margin-bottom: 0px;
      }
    }
    margin-bottom: 120px;
  }

  .timeline__wrap {
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    //margin-bottom: 120px;

    &:after {
      content: '';
      height: 100%;
      width: 1px;
      background-color: ${hover};
      top: 0;
      //bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }

    &__single {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 120px;
      width: 100%;

      &:nth-child(even){
        .timeline__wrap__single__img__img{
          display: flex ;
          justify-content: end;
        }
      }
      &:nth-child(odd){
        .timeline__wrap__single__img__img{
          display: flex ;
          justify-content: start !important;
        }
      }
      
      &:nth-last-child(1) {
        margin-bottom: 0;
        z-index: 2;
        background-color: #EDEEEF;
      }
     
      

      &__number {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid ${hover};
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background-color: ${hover};
        z-index: 2;

        p {
          color: #FFF;
          font-size: 12px;
          margin: 0;

        }
      }

      &__text {
        width: calc(50% - 115px);

        h4 {         
          margin: 0 0 20px 0;
          color: ${text};
        }
      }

      &__img {
        width: calc(50% - 120px);       
      }

      &:nth-of-type(odd) {
        .timeline__wrap__single__text {
          text-align: right;
        }
      }

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }

    }

    &__last {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100px;
      margin: auto;
      width: fit-content;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #F9F9F9;

      img {
        width: 60px;
        object-fit: contain;
        margin-bottom: 25px;
      }

      p {
        margin: 0;
        font-size: 20px;
        text-align: center;
        color: ${hover};
        font-weight: 600;
        text-transform: uppercase;
        line-height: 26px;
      }
    }

  }

  @media (max-width: 991px) {
    .timeline__wrap__single__img, .timeline__wrap__single__text {
      width: calc(50% - 90px);
    }

    .timeline__wrap__single__text {
      h4 {
        margin-bottom: 20px;
      }

      h5 {
        display: block;
      }
    }

    .timeline__wrap__single__video {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .timeline__wrap__single__img, .timeline__wrap__single__text {
      width: calc(50% - 60px);
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 50px;
    .timeline__wrap__single__img {
      display: none;
    }

    .timeline__wrap {
      padding: 0px 15px;
      margin-bottom: 40px;

      &:after {
        right: auto;
        left: 35px;
      }

      &__single {
        flex-direction: row !important;
        padding-left: 60px;
        margin-bottom: 60px;

        &__text {
          width: calc(100%);
          text-align: left !important;
        }
      }

      &__last {
        right: auto;
        flex-direction: row;
        width: 100%;
        left: 15px;
        bottom: -20px;

        img {
          width: 40px;
          margin: 0 20px 0 0;
        }

        br {
          display: none;
        }

        p {
          font-size: 15px;
          line-height: 17px;
        }
      }
    }

    .timeline__wrap__single__number {
      height: 40px;
      width: 40px;
      right: auto;
      top: 0px;

      p {
        font-size: 18px;
      }
    }

  }

`;
export default Timeline;
