import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {hover} from "../../styles/globalStyleVars";
const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <Title text={data?.title} margin={'0 0 80px 0'}/>
                    </Col>
                </Row>
                <Row >
                    <Col md={12} >
                        <div className={'first-icon'}>
                            <img src={data?.list?.[0]?.logo}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        data?.list && data?.list?.length>0 &&
                        data?.list?.slice(1,data?.list?.length)?.map((element)=>{
                            return(
                                <Col md={3}>
                                    <div className={'icon'} data-lag="0.3">
                                        <img src={element?.logo}/>
                                    </div>
                                </Col>
                            )
                        })
                    }

                    <Col md={12}>
                        <h5 className={'split-up'}>{data?.description}</h5>
                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: #FCFEFF;

  .first-icon {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(215, 216, 218, 0.5);
  }

  .icon {
    margin-top: 40px;
  }

  h5 {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    .first-icon {
      margin-bottom: 0px;
    }

    h5 {
      margin-top: 40px;
    }
  }
`
export default MyComponent;