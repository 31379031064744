import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";
import Title from "../Title";
import SliderPrevWhite from "../SliderPrevWhite";
import SliderNextWhite from "../SliderNextWhite";
import {Img} from "../Img";


const MyComponent = ({data, testimonial}) => {

    const [show, setShow] = useState(false);
    const [videoShow, setVideoShow] = useState();


    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    return (
        <StyledComponent className={'testimonial pt-200 pb-200'} >

            <div className={'bg-noise'}/>
            <Container>
                <Row className={'testimonial__top'}>
                    <Col sm={12} md={6}>
                        {
                            data?.title && <Title color={'#EDEEEF'} margin={'0'} text={data?.title}/>
                        }
                    </Col>
                    <Col sm={12} md={{span: 6}} className={'d-flex align-items-end '}>
                        <ul>
                            <li className={'slider_prev'}><SliderPrevWhite color={'#56575A'}
                                                                           background={'#FFFFFF'}
                                                                           hoverBackground={'#F16A11'}
                                                                           hoverColor={'#FFFFFF'}
                                                                           hoverBorder={'#F16A11'}

                            /></li>
                            <li className={'slider_next'}><SliderNextWhite color={'#56575A'}
                                                                           background={'#FFFFFF'}
                                                                           hoverBackground={'#F16A11'}
                                                                           hoverColor={'#FFFFFF'}
                                                                           hoverBorder={'#F16A11'}
                            /></li>
                        </ul>
                    </Col>
                </Row>

                {
                    data?.list && data?.list?.length>0 &&
                    <div className={'testimonial__slider'}>
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={900}
                                initialSlide={0}

                                navigation={{
                                    prevEl: '.testimonial .slider_prev',
                                    nextEl: '.testimonial .slider_next',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                            // autoplay={false}

                            // autoplay={{
                            //     delay: 5000,
                            //     disableOnInteraction: false,
                            // }}
                        >
                            {
                                data?.list && data?.list?.length>0 &&
                                data?.list?.map((element,index)=>{
                                    return(
                                        <SwiperSlide onClick={() => setVideoShow(`${element?.video_id}`)} key={index}>
                                            <div className="testimonial__slider__single flex-wrap d-flex">
                                                <Col sm={12} md={5} className={'p-0'}>
                                                    <div className="testimonial__slider__single__img"
                                                         onClick={handleShow}>
                                                        <Img src={element?.image}/>
                                                        {
                                                            element?.video_id ?
                                                                <div className="video-btn">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         width="140" height="140" viewBox="0 0 140 140">

                                                                        <g id="Button_-_Video" data-name="Button - Video" transform="translate(30 30)">
                                                                            <g transform="matrix(1, 0, 0, 1, -30, -30)" filter="url(#Ellipse_386)">
                                                                                <circle id="Ellipse_386-2" data-name="Ellipse 386" cx="40" cy="40" r="40" transform="translate(30 30)" fill="#edeeef"/>
                                                                            </g>
                                                                            <path id="Polygon_2" data-name="Polygon 2" d="M12,0,24,18H0Z" transform="translate(52 28) rotate(90)" fill="#f16a11"/>
                                                                        </g>
                                                                    </svg>

                                                                </div> : ''
                                                        }

                                                    </div>
                                                </Col>

                                                <Col sm={12} md={{span: 6, offset: 1}}>
                                                    <div className="testimonial__slider__single__content">
                                                        <h4 className={'split-up'}>{element?.title}</h4>
                                                        <img src="/images/static/qoutes.svg" alt=""/>
                                                        <p className={'split-up'}>{element?.description}</p>

                                                        <div className="testimonial__slider__single__content__bottom split-up">
                                                            <p>{element?.name}</p>
                                                            <p>{element?.designation}</p>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }


                        </Swiper>
                    </div>
                }

            </Container>

            {/*modal*/}
            <Modal className={'modal-video'} show={show} onHide={handleClose}>

                <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
                     height="13.423"
                     viewBox="0 0 13.426 13.423">
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                          d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                          transform="translate(-11.285 -11.289)" fill="#fff"/>
                </svg>


                <Modal.Body>
                    <iframe width="560" height="315"
                            src={`https://www.youtube.com/embed/${videoShow}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </Modal.Body>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;  
  background-color: #D6D6D6;
  overflow: hidden;
  
  .bg-noise{
    position: absolute;
    inset: 0px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #56575A;
    
    &:after{
      content: "";
      background-image: url('/images/static/noise.svg');
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0px;
      opacity: 1
    }
  }

  .testimonial__top {
    ul {
      display: flex;
    }
  }

  .testimonial__slider {
    margin-top: 65px;

    &__single {
      &__img {
        position: relative;
        padding-top: calc(320 / 470 * 100%);
        //cursor: pointer;
        overflow: hidden;

        .global-image {

          overflow: hidden;

          img {
            transition: 1s all cubic-bezier(.25, .74, .22, .99);
            transform: scale(1.01);
          }
        }
        .video-btn {
          position: absolute;
          width: fit-content;
          height: fit-content;
          inset: 0 0 0 0;
          margin: auto;
        }

        &:hover {
          .global-image {
            img {
              transform: scale(1.06);
            }
          }
        }
      }

      &__content {
        position: relative;

        img {
          position: absolute;
          top: -14px;
          left: -26px;
        }

        h4 {
          margin-bottom: 25px;
          font-size: 24px;
          line-height: 32px;
          color: #EDEEEF;
        }

        p {
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          color: #EDEEEF;
        }

        &__bottom {
          margin-top: 60px;
          p{
            &:last-child{
              font-size: 14px;
              line-height: 20px;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .swiper-slide {
    padding-top: 15px;
  }

  @media (max-width: 768px) {
    .video-btn {
      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  @media (max-width: 767px) {


    .testimonial__slider {
      margin-top: 40px;

      &__single__content {
        margin-top: 40px;
        margin-left: -15px;
        margin-right: -15px;

        img {
          left: 0px;
        }

        &__bottom {
          margin-top: 40px;
        }
      }
    }


  }
`;

export default MyComponent;
