import React, {useState} from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, StyledMap} from '@react-google-maps/api';
import Button from "./../Button";
import styled from 'styled-components';
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import {Img} from "../Img";
import {medium, text, white} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";
const GoogleMapsMarkers = ({data}) => {

    const mapStyles = {
        height: '100%',
        width: '100%',
    };

    const defaultCenter = {
        lat: 23.801927, // Replace with marker latitude
        lng: 90.4029675, // Replace with marker longitude
    };

    const [selectedMarker, setSelectedMarker] = useState(null);


    const markers = data && Array.isArray(data) ? data.map((item, index) => {
        return {
            id: index,
            position: {lat: parseFloat(item?.latitude), lng: parseFloat(item?.langtitude)},
            icon: '/images/static/marker.svg',
            content: item?.post_title,
            type: item?.project_type,
            type2: item?.project_location,
            size: item?.measurement,
            image: item?.map_gallery,
            slug: item?.post_name,
            address: item?.project_address,
        };
    }) : [];


    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
    };


    const customMapStyle = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]; // Paste your copied JSON style here

    const mapOptions = {
        styles: customMapStyle,
    };


    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const handleButtonClick = (selectedId) => {

        setSelectedMarkerId(selectedId)
    };
    const handleButtonClickClose = () => {
        setSelectedMarkerId(null)
    };

    return (
        <StyledComponent>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={12.3}
                center={defaultCenter}
                options={mapOptions}
            >
                {markers.map((marker) => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        icon={marker.icon}
                        onClick={() => handleMarkerClick(marker)}
                    >
                    </Marker>
                ))}
                {selectedMarker && (
                    <InfoWindow
                        position={selectedMarker.position}
                        onCloseClick={handleInfoWindowClose}
                    >
                        <div>
                            <p>{selectedMarker.content}</p>
                            <ul>
                                <li>{selectedMarker.type2}</li>
                            </ul>
                            {
                                window?.innerWidth > 767 &&
                                <div onClick={() => handleButtonClick(selectedMarker.id)} className={'map-button'}
                                     data-id={selectedMarker.id}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.707" height="11.414"
                                         viewBox="0 0 16.707 11.414">
                                        <g id="Arrow" transform="translate(-1307.5 -242.793)">
                                            <line id="Line_12400" data-name="Line 12400" x2="5" y2="5"
                                                  transform="translate(1318.5 243.5)" fill="none" stroke="#f1eee9"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_12401" data-name="Line 12401" y1="5" x2="5"
                                                  transform="translate(1318.5 248.5)" fill="none" stroke="#f1eee9"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_12402" data-name="Line 12402" x2="15"
                                                  transform="translate(1308 248.5)" fill="none" stroke="#f1eee9"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>
                            }
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
            {
                window?.innerWidth > 767 &&
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: true,
                            share: false
                        }
                    }
                >
                    <div className="google-map-side-gallery">
                        {markers.map((marker) => (
                            <div key={marker.id} data-item={marker.id}
                                 className={`item-single-gallery ${selectedMarkerId === marker.id ? 'active' : ''}`}>
                                <div onClick={handleButtonClickClose} className="close-modal-map">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.414" height="10.414"
                                         viewBox="0 0 10.414 10.414">
                                        <g id="Group_20065" data-name="Group 20065"
                                           transform="translate(-480.793 -6228.793)">
                                            <line id="Line_12384" data-name="Line 12384" x2="9" y2="9"
                                                  transform="translate(481.5 6229.5)" fill="none" stroke="#f9f5f2"
                                                  stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_12385" data-name="Line 12385" x1="9" y2="9"
                                                  transform="translate(481.5 6229.5)" fill="none" stroke="#f9f5f2"
                                                  stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                </div>

                                <div className="content">
                                    <h4>{marker.content}</h4>
                                    <p>{marker.type2}</p>
                                    <ul>
                                        <li>
                                            <img src={'/images/static/location.svg'}/>
                                            <p>Address</p>
                                            <p>{marker.address}</p>
                                        </li>
                                        <li>
                                            <img src={'/images/static/area.svg'}/>
                                            <p>Land Area</p>
                                            <p>{marker.size}</p>
                                        </li>
                                    </ul>
                                    <div className="gallery">
                                        <ul>
                                            {
                                                marker.image?.length > 0 &&
                                                marker.image?.map((e, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <LightgalleryItem group={'any'} src={e?.image}>
                                                                <div className="img-wrapper">
                                                                    <Img src={e?.image}/>
                                                                </div>
                                                            </LightgalleryItem>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <Link to={`/project/${marker.slug}`}>
                                        <div className={'round-btn'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.707" height="11.414"
                                                 viewBox="0 0 16.707 11.414">
                                                <g id="Arrow" transform="translate(-1307.5 -242.793)">
                                                <line id="Line_12400"
                                                      data-name="Line 12400" x2="5" y2="5" transform="translate(1318.5 243.5)" fill="none" stroke="#f1eee9"
                                                      stroke-linecap="round" stroke-width="1"></line>
                                                <line id="Line_12401" data-name="Line 12401" y1="5" x2="5" transform="translate(1318.5 248.5)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1">

                                                </line>
                                                <line id="Line_12402" data-name="Line 12402" x2="15" transform="translate(1308 248.5)" fill="none" stroke="#f1eee9" stroke-linecap="round" stroke-width="1"></line>
                                            </g>
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </LightgalleryProvider>
            }
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh;
  position: relative;

  .map-button {
    width: 38px;
    height: 38px;
    border: 1px solid ${white};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      transform: translateX(0);
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &:hover {
      svg {
        //transform: translateX(10px);
      }
    }
  }

  .google-map-side-gallery {
    .item-single-gallery {
      background: ${text};
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      position: absolute;
      padding: 40px;
      transform: translateX(100%);
      min-width: 468px;
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);

      .close-modal-map {
        width: 38px;
        height: 38px;
        border: 1px solid ${white};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .content {
        height: calc(100vh - 60px);
        overflow-x: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }


        h4 {
          //font-size: 24px;
          font-weight: 500 !important;
          // font-family: ${medium};
          //line-height: 32px;
          color: ${white};
          text-transform: uppercase;
          width: 60%;
        }
        p{
          text-transform: uppercase;
          color: ${white};
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 60px;

        }
        .round-btn{
          width: 38px;
          height: 38px;
          border: 1px solid ${white};
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          li {
            margin-bottom: 10px;
            margin-bottom: 10px;
            display: inline-flex;
            align-items: center;

            svg {
              height: 20px;
              width: 20px;
              margin-right: 20px;
            }
            img{
              margin-right: 10px;
            }

            p {
              font-size: 14px;
              line-height: 20px;
              color: #EDEEEF;
              text-transform: uppercase;
              margin: 0;
              width: 30%;
              font-weight: 300;

              &:first-child {
                opacity: 0.5 !important;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .gallery {
        ul {
          margin: 0;
          gap: 15px;
          flex-wrap: wrap;
          flex-direction: unset;

          li {
            flex: 0 0 calc(50% - 15px);
            margin: 0;

            .react_lightgallery_item {
              width: 100%;
              cursor: pointer;
            }

            .img-wrapper {
              padding-top: calc(100 / 160 * 100%);
              position: relative;
              min-height: 100px;
            }
          }
        }
      }

      &.active {
        transform: translateX(0);
      }


    }
  }


  .poi-info-window div, .poi-info-window a {
    background: transparent;
    color: #F1EEE9;

  }
`;
export default React.memo(GoogleMapsMarkers)