import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,download,file
                }) => {


    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
        >
            {
                download?
                    <a href={`${file}`} target={'_blank'} download>
                       <span> {text}  </span>
                   </a> :
                    src ? (
                        <Link target={target || '_self'} to={src || '/'}>
                            {text}
                        </Link>
                    ) : (
                        <a target={target || '_self'}>{text}</a>
                    )

            }
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: fit-content;
    //cursor: pointer;

    a {
      display: flex;
      align-items: center;
      color: ${p => p.color || '#565440'};
      font-size: ${props => props.fontSize || '12'}px;
      font-weight: ${props => props.fontWeight || 500};
      margin: 0;
      padding: 15px 20px;
      line-height: ${props => props.lineHeight || '20'}px;
      text-transform: uppercase;
      background-color: ${props => props.background || `transparent`};
      letter-spacing: ${props => props.letterSpacing || `1.2px`};
      position: relative;
      border-radius: ${props => props.borderRadius || '0'};
      z-index: 0;
      transition: border .3s ease;

      &:before {
        position: absolute;
        content: '';
        height: 38px;
        width: 100%;
        box-shadow: 0 0 0 1px ${p => p.borderColor || '#F16A11'};
        left: 0;
        border-radius: 38px;
        transition: all .5s cubic-bezier(0.25, 0.74, 0.22, 0.99);
        animation-delay: -2.25s;
        z-index: -1;
        animation: button-opacity 4.5s infinite;

      }
      //
      // &:after {
      //   position: absolute;
      //   content: '';
      //   height: 40px;
      //   width: 40px;
      //   box-shadow: 0 0 0 1px ${p => p.borderColor || '#C49F8A'};
      //   left: 0;
      //   border-radius: 40px;
      //   transition: all .5s cubic-bezier(0.25, 0.74, 0.22, 0.99);
      //   animation: button-halo-animation 4.5s infinite;
      // }


      &:hover {
        color: ${p => p.color || '#565440'} !important;

        &:before {
          width: 40px;
        }

        &:after {
          animation: none;
          opacity: 0;
        }
      }

      @keyframes button-halo-animation {
        0% {
          transform: scale(1);
          opacity: 0;
        }
        50% {
          transform: scale(1);
          opacity: 1;
        }
        to {
          transform: scale(1.5);
          opacity: 0;
        }
      }

    }
  }

  @media (max-width: 768px) {
    a {
      width: fit-content !important;
      justify-content: center !important;

      &:before {
        width: 100% !important;
      }

      &:after {
        display: none;
      }
    }
  }



`;


export default Button;
