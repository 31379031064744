import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import MediaList from "../../components/media/MediaList";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {fetchMedia} from "../../api/redux/newsroom";
import {Helmet, HelmetProvider} from "react-helmet-async";
const MyComponent = () => {

    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA
        dispatch(fetchMedia([api_url]))
    }, [])

    let getPost = useSelector(state => state.media)


    // data refactor

    let medialist=getPost?.posts?.media;
    let banner=getPost?.posts?.page_data?.banner;
    let meta=getPost?.posts?.page_data?.meta;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Media | Rancon Real Estate Ltd.</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>
                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>
                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>
                }            </Helmet>
            <StyledComponent>
                <InnerBanner title={banner?.title} subtext={banner?.subtitle}
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <MediaList data={medialist}/>

            </StyledComponent>
        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
`
export default MyComponent;