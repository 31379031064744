import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Bod from "../../components/about/Bod";
import {useDispatch, useSelector} from "react-redux";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import Testimonials from "../../components/about/Testimonials";
import Vision from "../../components/about/VisionMission";
import ServiceSlider from "../../components/about/ServiceSlider";
import TextWithIcon from "../../components/about/TextWithIcon";
import History from "../../components/about/History";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/about";

const MyComponent = () => {


    const dispath = useDispatch()
    const location = useLocation();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))
    }, [])

    let getPost = useSelector(state => state.about)

    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let management=getPost?.posts?.[0]?.management;
    let mission_vision=getPost?.posts?.[0]?.mission_vision;
    let overview=getPost?.posts?.[0]?.overview;
    let corporate_value=getPost?.posts?.[0]?.corporate_value;
    let concern=getPost?.posts?.[0]?.sister_concern;
    let testimonial=getPost?.posts?.[0]?.testimonial;
    let timeline=getPost?.posts?.[0]?.timeline;
    let meta=getPost?.posts?.[0]?.meta;

    useEffect(() => {
        window.scroll(0, 0)
    }, [location?.pathname,getPost])
    // console.log(management);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About | Rancon Real Estate Ltd.</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>
                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>
                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>
                }
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.title} subtext={banner?.subtitle}
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <Overview desc={overview?.description} title={overview?.title} pdf={overview?.pdf} nobg hasbtn about/>
                <Vision data={mission_vision}/>
                <ServiceSlider data={corporate_value}/>
                <TextWithIcon data={concern}/>
                <Bod data={management} testimonial={testimonial}/>
                {
                    testimonial?.length>0 &&
                    <Testimonials data={testimonial}/>
                }

                <History data={timeline} testimonial={testimonial}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
