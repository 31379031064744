import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import HtmlParser from 'react-html-parser';
import ReactHtmlParser from "react-html-parser";
import {hover, text} from "../../styles/globalStyleVars";

const Address = ({ top, posts,data }) => {

    return (
        <StyleAddress top={top} className={'pt-200'}>
            <Container>
                <Row>
                    <Col sm={5} className='contact-map'>
                        <div className="contact-map__img">
                            <a target="_blank" href={'https://www.google.com/maps'} />
                            <img src={data?.image} alt="map-image" />
                        </div>
                    </Col>

                    <Col sm={{ span: 5, offset: 1 }} className='contact-address'>
                        <div className="contact-address__top">
                            <p className={'split-up'}>Corporate Office</p>
                            {data?.title && <h4 className={'split-up'}>{ReactHtmlParser(data?.title)}</h4>}

                        </div>

                        <div className="contact-address__bottom">
                            {
                                data?.hotline &&
                                <div className="contact-address__bottom__single">
                                    <p className={'split-up'}>Hotline</p>
                                    {data?.hotline && <h4 className={'split-up'}><a href={`tel:${data?.hotline}`}>{data?.hotline}</a></h4>}
                                </div>
                            }

                            {
                                data?.email &&
                                <div className="contact-address__bottom__single">
                                    <p className={'split-up'}>EMAIL</p>
                                    {data?.email && <h4 className={'split-up'}><a href={`mailto:${data?.email}`}>{data?.email}</a></h4>}
                                </div>
                            }
                        </div>
                        {
                            data?.operating_hour &&
                            <div className="contact-address__bottom">
                                <div className="contact-address__bottom__single">
                                    <p className={'split-up'}>Operating Hours</p>
                                    {ReactHtmlParser(data?.operating_hour)}
                                </div>
                            </div>
                        }


                    </Col>
                </Row>
            </Container>
        </StyleAddress>
    )
}
const StyleAddress = styled.section`
  background-color: #EDEEEF;
  position: relative;

  &:after {
    content: "";
    width: 80%;
    top: ${props => props.top || '0'};
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }

  .contact-map {
    &__img {
      padding-top: calc(580 / 470 * 100%);
      position: relative;
      height: 100%;
      z-index: 1;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }

  .contact-address {
    padding-bottom: 150px;
    position: relative;
    z-index: 2;

    &__top {
      margin-bottom: 80px;

      h4 {
        //font-size: 50px;
        //font-weight: bold;
        //line-height: 50px;
        color: #56575A;
        text-transform: capitalize;
        margin: 0 0 25px 0;
      }

      p {
        color: rgba(86, 87, 90, 0.5);
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;

      @media(max-width: 767px){
        margin-bottom: 40px;

      }
      &__single {
        &:last-child{
          h4{
            a{
              color: ${text} !important;
              &:hover{
                color: ${hover} !important;
              }
            }
          }

        }
        p {
          color: rgba(86, 87, 90, 0.5);
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 14px 0;
          text-transform: uppercase;

        }

        a:not(.link a) {
          font-size: 20px;
          font-weight: bold;
          color: ${hover};
          line-height: 25px;
          display: block;

          &.mail {
            margin-top: 53px;
            color: #56575A;
          }

          //&:hover {
          //  text-decoration: underline;
          //}
        }

        .link {
          margin-top: 50px;
        }
      }
    }

  }

  @media (max-width: 769px) {
    padding-top: 75px;
    //padding-bottom: 75px;
    .contact-map {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .contact-address {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: 50px;

      &__top {
        margin-bottom: 30px;

        h2 {
          font-size: 30px;
          line-height: 35px;
        }
      }
    }

  }

`;
export default Address;
