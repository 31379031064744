import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import ProjectList from "../../components/project/ProjectList";
import {useSelector} from "react-redux";
import {ApiParam} from "../../api/network/apiParams";

const MyComponent = () => {


    let getPost = useSelector(state => state.project);

    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let meta=getPost?.posts?.[0]?.meta;
    let list=getPost?.posts?.[0]?.list;
    let optionList=getPost?.posts?.[0]?.filter;

    // step -01

    const [selectStatus, setSelectStatus] = useState()
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')


    // step -02

    // api call
    useEffect(() => {
        let param = {
            [ApiParam.status_id]: selectStatus,
            [ApiParam.type_id]: selectType,
            [ApiParam.location_id]: selectLocation
        }
    }, [selectStatus, selectType, selectLocation])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Projects | Rancon Real Estate Ltd.</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>
                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>
                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>
                }
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.title}
                             subtext={banner?.subtitle}
                             img={banner?.image}
                             mimg={banner?.mobile_image}
                />
                <ProjectList data={list} optionList={optionList}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
