import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, text} from "../styles/globalStyleVars";

const MyComponent = ({img,link,width,height}) => {
    return (
        <StyledComponent width={width} height={height}>
            {
                link?
                    <div className={'d-flex'}>
                        <img src={img} alt=""/>
                    </div> :
                    <div className={'social-media d-flex'}>
                        <a href={link} target={'_blank'} className={'d-flex'}>
                            <img src={img} alt=""/>
                        </a>
                    </div>
            }

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //position: fixed;
  height: ${props => props.height || '25px'};
  width: ${props => props.width || '25px'};
  border-radius: 50%;
  background-color: #EDEEEF;
  z-index: 9999;
  right: 40px;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  //cursor: pointer;
  overflow: hidden;
  //
  a {
    //position: absolute;
    //height: 100%;
    //height: 25px;
    //width: 25px;
    //border-radius: 50%;
    //top: 0;
    //bottom: 0;
    z-index: 5;
  }

  img {
    transition: .3s ease;
    margin-bottom: 0px !important;
  }
  // ... (previous code)


  &:before {
    inset: unset !important;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 100%; /* Change this line to start from the bottom */
    //left: 0 !important;
    //background-color: red;
    background-color: ${hover};
    margin: auto;
    transition: transform 0.5s ease; /* Change this line for the animation */
    transform-origin: bottom;
    transform: scaleY(0); /* Start with scaleY(0) to hide the element */
    height: 25px;
    width: 25px;
    border-radius: 50%;
    
  }

  // ... (remaining code)


  &:hover {
    span {
      color: ${props => props.hoverColor || `#F2F2F2`};
    }


    img {
      filter: invert(44%) sepia(28%) saturate(31%) hue-rotate(34deg) brightness(150%) contrast(147%);
    }

    &:before {
      transform: scaleY(1); /* Expand vertically on hover */
    }
  }
`;

export default MyComponent;
