import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover, text} from "../styles/globalStyleVars";

const MyComponent = () => {
    return (
        <StyledComponent>
            <div>
                <a href="callto:16677"/>
                <img src="/images/static/call.svg" alt=""/>
            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: fixed;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${hover};
  z-index: 9999;
  right: 40px;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  //cursor: pointer;
  overflow: hidden;
  box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.22);

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 5;
  }

  img {
    transition: .3s ease;
  }
  // ... (previous code)


  &:before {
    inset: unset !important;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 100%; /* Change this line to start from the bottom */
    left: 0 !important;
    background-color: ${text};
    margin: auto;
    transition: transform 0.5s ease; /* Change this line for the animation */
    transform-origin: bottom;
    transform: scaleY(0); /* Start with scaleY(0) to hide the element */
    height: 100%;
    width: 100%;
    border-radius: 27px;
  }

  // ... (remaining code)


  &:hover {
    span {
      color: ${props => props.hoverColor || `#F2F2F2`};
    }


    img {
      filter: invert(44%) sepia(28%) saturate(31%) hue-rotate(34deg) brightness(150%) contrast(147%);
    }

    &:before {
      transform: scaleY(1); /* Expand vertically on hover */
    }
  }

  @media(max-width: 767px){
    right: 15px;
  }
`;

export default MyComponent;
