import React from 'react';
import styled from 'styled-components';
import {hover, hoverr, medium} from "../styles/globalStyleVars";
import {Link, useLocation} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    hoverColor,
                    svg,
                    hoverBorder,
                    locationFrom
                }) => {

    const location = useLocation();


    //To get the first & second slug
    const pathSegments = location.pathname.split("/");
    const secondSegment = pathSegments[2];

    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
                   svg={svg}
                   hoverBorder={hoverBorder}


        >
            {locationFrom==='project' ? (

                <Link to={`/project/${secondSegment}#request-callback`}>
                    <span>{text}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                        <g id="Arrow" transform="translate(-648 -1595.793)">
                            <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                                <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                </Link>
            ) : (
                <a href={`tel:${text}`}>
                    <span>{text}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                        <g id="Arrow" transform="translate(-648 -1595.793)">
                            <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                                <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '40'}px;
    //cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '12'}px;      
      font-weight: ${props => props.fontWeight || 500};
      letter-spacing: 1.2px;
      margin: 0;
      line-height: ${props => props.lineHeight || '16'}px;
      background-color: ${props => props.background || `transparent`};
      position: relative;
      //border: 1px solid #FFFFFF ;
      border-radius: ${props => props.borderRadius || '19'}px;
      border: 1px solid ${props => props.borderColor || '#FFFFFF'};
      overflow: hidden;
      z-index: 0;
      transition: border .3s ease;
      padding: 12px 20px 12px 20px ;
      box-sizing: border-box;
      
      svg{
        margin-left: 15px;
      }

      span {
        transition: color .3s ease;
        color: ${props => props.color || `#F2F2F2`};
        position: relative;
        z-index: 2;
      }
      &:before {
        top: 100%;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        background-color: ${p => p.hoverBackground || '#404040'};
        height: 100%;
        width: 100%;
        margin: auto;
        transition: all .5s ease;
        border-radius: 24px;       
     
      }

      &:hover {
        border: 1px solid ${props => props.hoverBorder || '#FFFFFF'};
        span {
          color: ${props => props.hoverColor || `#F2F2F2`};
        }

        svg {
          z-index: 9999;
          g{
            line {
              stroke: ${props => props.hoverColor || '#F2F2F2'};
            }
          }
        }

        &:before {
          top: 0;
          border: 1px solid ${props => props.hoverBorder || 'blue'};
          //height: 100%;
          //width: 100%;
        }
      }

      &:focus {
        color: #3C3C3B;
      }
    }
  }

`;


export default React.memo(Button);
