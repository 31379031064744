import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "../ImageParallax";
import {white} from "../../styles/globalStyleVars";

const InnerBanner = ({img,title, size,project,notFull,location,type,icon,mimg}) => {
    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner parallax '>
            {
                window?.innerWidth>767 ?
                    img && <ImageParallax parallax banner={true} src={img ? img : '/images/dynamic/project/blur.jpg'} />
                     :  <ImageParallax banner={true} src={mimg ? mimg : img} />
            }

            <Container>
                <Row>
                    <Col md={{span:8}}>
                        {icon && <img src={icon} alt={'icon'}/>}
                        <h1>{title ? reactHtmlParser(title) : ''}</h1>
                        <div className={'address'}>
                            <h6>{type}</h6>
                            <span />
                            <h6>{location}</h6>
                            <span />
                            <h6>{size}</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '75vh' : '100vh'} ;
  position: relative;
  height:${props => props.notFull ? '75vh' : '100vh'};
  overflow: hidden;

  &:after {
    position: absolute;
    background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  span{
    width: 4px;
    height: 4px;
    background-color: #FFFFFF;
    border-radius: 50%;
    margin: 0 15px ;    
  }

  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
    
    img{
      margin-bottom: 20px;
    }

    h1 {
      margin-bottom: 20px ;
    }
    h6{
      color: ${white};
    }

    h1, p {
      color: #F1EEE9;
      text-transform: uppercase;
      font-weight: 900;

    }

    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    .address{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media(max-width: 767px){
    padding-top: 75vh ;
    
    .container {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      text-align: left;
      bottom: 60px;
      
      .address{
        justify-content: start;
      }

      h1 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  }
`;

export default InnerBanner;
