import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {gsap} from "gsap";
import {Container, Row, Col, ListGroup, Dropdown, DropdownButton} from "react-bootstrap";


import ScrollTrigger from 'gsap/ScrollTrigger';
import {hover, medium, white} from "../../styles/globalStyleVars";
import {Link, useHistory, useLocation} from "react-router-dom";

const MyComponent = () => {


    const history=useHistory();
    const location = useLocation();
    //To get the first & second slug
    const pathSegments = location.pathname.split("/");
    const secondSegment = pathSegments[2];

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const col4Element = document.querySelector('.mobile-nav');

        gsap.to(col4Element, {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            scrollTrigger: {
                trigger: col4Element,
                start: 'top top',
                end: 'bottom center',
                scrub: true, // Adjust this value if needed
            },
        });

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, [history?.location]);

    const scrollToSection = (sectionRef) => {
        const yOffset = -80; // Adjust this value to offset the scroll position if needed
        const y = sectionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <StyledComponent >
            <Row>
                <Col sm={12}>
                    <Link to={`/project/${secondSegment}#request-callback`}>
                        <div className={'mobile-nav'}>
                            <p>REQUEST A CALLBACK</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                                <g id="Arrow" transform="translate(-648 -1595.793)">
                                    <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                                        <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                                        <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                    <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </svg>
                        </div>
                    </Link>

                </Col>
            </Row>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  
  .mobile-nav{
    background-color: ${hover};
    z-index: 99999999999;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

  }
  .pinned {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Adjust the z-index as needed */
    /* Add other styles as needed */
  }
    p{
      font-size: 12px;
      line-height: 16px;
      font-family: ${medium};
      color: ${white};
      
    }
`
export default MyComponent;