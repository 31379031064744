import React, {useState,useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import ModalVideo from "react-modal-video";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import {grayText, hover, text} from "../styles/globalStyleVars";
import {Img} from "./Img";

const ProjectList = ({data,fromDetail}) => {


    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };




    return (
        <StyledProjectList className={'pt-200 pb-200 parallax'} fromDetail={fromDetail}>
            <Container className={''}>
                <Row>
                    <Col md={12} onClick={() => handelOpen(true,'')} >
                        <div className='project fade-up'>
                            <div className='video-box project__img'>
                                {
                                    window?.innerWidth>767 ?
                                        <Img parallax src={data?.image ? data?.image : '/images/dynamic/home/video.jpg'} alt={'project video'}/> :
                                        <Img src={data?.image ? data?.image :'/images/dynamic/home/video.jpg'} alt={'project video'}/>
                                }
                                <div className="">
                                    <div className="hover-btn hover">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
                                            <path id="Polygon_2" data-name="Polygon 2" d="M12,0,24,18H0Z" transform="translate(18) rotate(90)" fill="#f16a11"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/*modal*/}
            <Modal className={'modal-video'} show={show} onHide={handleClose}>

                <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="140"
                     height="140"
                     viewBox="0 0 140 140">
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                          d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                          transform="translate(-11.285 -11.289)" fill="#fff"/>
                </svg>


                <Modal.Body className={'video'}>
                    <iframe  width="560" height="315"
                             src={`https://www.youtube.com/embed/${data?.video_id}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
                             title="YouTube video player" frameBorder="0"
                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                             allowFullScreen/>
                </Modal.Body>

            </Modal>


        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`
  background-color: #EDEEEF;
  position: relative;
  overflow: hidden;
  padding-top: ${props => props?.fromDetail ? '0px' : ''} !important;
  z-index: 1;
  &:after {
    height: 40%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${grayText};
    content: "";
    display: ${props => props.fromDetail ? 'block' : 'none'};
    z-index: -1;
  }

  .video_text{
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 15px;
    h3{
      font-weight: 400;
      color: ${text};
      font-size: 40px;
      line-height: 48px;
    }
    @media(max-width: 767px){
      padding-top: 40px;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .overflow {
    overflow: hidden;
  }
  .video-box {
    position: relative;
    //cursor: pointer;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 60px;

    img {
      width: 100%;

    }

    .hover-btn {
      height: 80px;
      width: 80px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;

      &.hover:after {
        border-radius: 50%;
      }

      svg {
        position: relative;
        z-index: 2;
      }

      path {
        transition: all .3s ease;
      }
      &:hover {

        &:after {
          height: 100%;
          width: 100%;
        }

        path {
          fill: #FFF;
        }
      }

    }


  }


  @media (max-width: 991px) {

    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40% !important;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 30px;
      right: -20px;
      transform: none;

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 50%;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
    .col-lg-6 {
      margin-bottom: 40px;

      :nth-child(even) {
        padding-right: 15px;
        padding-left: 15px;
      }

      :nth-child(odd) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 30px;
      right: -20px;
      transform: none;

      svg {
        width: 150px;
        height: 150px;
      }
    }

    .shadow-left {
      transform: none;
      left: 0;
      bottom: 30px;
      top: auto;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .dc-btn {
      margin-top: 30px !important;
      margin-bottom: 0 !important;
    }

  }
  @media (max-width: 1200px) {
    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: none;

      svg {
        width: 40% !important;
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    .project__img__content {
      left: 20px;
    }
  };




  //project 
  .project {
    &__img {
      position: relative;
      padding-top: calc(680/1170*100%);
      z-index: 4;
      //cursor: pointer;

      a.wrapped {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 5;
      }

      &:after {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0px;
        content: '';
      }


      &__content {
        position: absolute;
        bottom: 35px;
        left: 40px;
        right: 40px;
        z-index: 1;
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        h2 {
          color: #F2EEE8;
          opacity: 1;
          font-weight: 400;
          font-size: 32px;
          line-height: 32px;
        }


        p {
          font-size: 20px;
          color: #F2EEE8;
          line-height: 28px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          margin-bottom: 7px;
          opacity: 0.6;

        }

      }
    }
    @keyframes moveUp {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-10px); /* Adjust the value as per your desired distance */
      }
    }
    &:hover {
      svg {
        .video-hover {
          cx: 30px;
          opacity: 1;
          transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
        }
        path{
          //fill:#ffffff;
          transition: all 0.5s ease;
        }
        // circle{
          //   fill: ${hover}
        //   animation: moveUp 0.5s ease; /* Apply the animation */
        // }
      }
    }
  }

  .video__box__inner__click {
    position: absolute;
    z-index: 8;
    inset: 0px;
    width: fit-content;
    height: fit-content;
    margin: auto;
    overflow: unset !important;

    svg {
      overflow: hidden;
      border-radius: 50%;

      .video-hover {
        cx: -30px;
        opacity: 0;
        transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
      }
      path{
        transition: all 0.5s ease;
      }
    }



    p {
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: #f2eee8;
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
      position: absolute;
      transition: all .3s ease;
      transform: translateY(10px);
    }



  }


  @media (max-width: 768px) {
    .project__img__content__bottom {
      display: none;

    }

    .project__img__content {
      transform: none;
      bottom: 26px;

    }
  }

`
export default React.memo(ProjectList);

