import React, {useRef, useEffect, useState} from "react";
import {gsap} from "gsap/gsap-core";
import {CSSPlugin} from 'gsap/CSSPlugin'
import styled from 'styled-components';
import Button from "./Button";
import StickyButton from "./StickyButton";
const MessageBox = ({bgColor, hoverColor, msgImg,locationFrom,text}) => {

    gsap.registerPlugin(CSSPlugin)
    const wrapRef = useRef(null)

    return (
        <StyledMessageBox ref={wrapRef} bgColor={bgColor} hoverColor={hoverColor} msgImg={msgImg} >
            <StickyButton locationFrom={locationFrom} text={text} color={'#FFFFFF'} background={'#F16A11'} hoverColor={'#56575A'} hoverBackground={'#FFFFFF'} hoverBorder={'#FFFFFF'} borderColor={'#F16A11'} img={'/images/static/arrow.svg'}/>
        </StyledMessageBox>
    )
}
const StyledMessageBox = styled.div`
  position: fixed;
  bottom: 20px; /* 40px from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  z-index: 9;
  overflow: hidden;
  background-color: transparent;
  height: 100px;

  @media (max-width: 767px) {
    bottom: -20px;
  }
`;


export default MessageBox;
