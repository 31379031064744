import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link, useHistory, useLocation} from "react-router-dom"
import ReactHtmlParser from "react-html-parser";
import ButtonV2 from "./ButtonV2";
import {CSSPlugin, gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Title from "./Title";
const MyComponent = ({title, subTitle,  link, data,desc,nobg,hasbtn,bg,about,pdf}) => {

    // const history=useHistory();

    // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    // gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CSSPlugin);
    //
    // useEffect(() => {
    //     let scroller = ScrollSmoother.create({});
    //
    //     if (window.innerWidth > 768) {
    //         // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    //
    //         // let scroller = ScrollSmoother.create({});
    //         scroller.effects('.new-parallax', {
    //             speed: 1.3,
    //             lag: 0,
    //             top: '0px',
    //             bottom: '0px'
    //         });
    //         //
    //         // scroller.effects('.box', {
    //         //     speed: 1.1,
    //         //     lag: 0,
    //         //     top: '0px',
    //         //     bottom: '0px'
    //         // });
    //     }
    // }, [history?.location?.pathname]);


    return (
        <StyledComponent id={'overview'} className={'about pt-200 pb-200'} nobg={nobg} hasbtn={hasbtn} bg={bg}  about={about}>

            <Container>
                <Row>
                    <Col  sm={{span: 10, offset: 1}}>
                        {
                            title ?
                                <div className={'d-flex justify-content-center align-items-center mobile'}>
                                    <Title text={title} fontSize={24} lineHeight={32}  center margin={'0 0 60px 0'} fontFamily={`"Avenir LT Pro 65 black"`}/>
                                </div> : ''
                        }
                        <div className={'para split-up'}>
                            {ReactHtmlParser(desc)}
                        </div>
                        {
                            hasbtn ?
                                <div className={'d-flex justify-content-center align-items-center mobile'}>
                                    <ButtonV2 text={'DOWNLOAD BROCHURE'} margin={'60px 0px 0px 0'} download file={pdf} target={'_blank'}/>
                                </div> : ''
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //position: relative;
  //overflow: hidden;
  background-color: #EDEEEF;

  // background-image: url(${'/images/dynamic/project/overview-bg.jpg'}); // Set the background image here
  background-image: ${props => props.nobg ? 'none' : `url(${props.bg})`};
  background-size: cover; // You can adjust the sizing as needed
  background-repeat: no-repeat;
  background-position: center center;

  h5{
    text-align: center;
    margin-bottom: 28px;
    &:last-child{
      margin-bottom: 0px;
    }
  }



  .shadow-right {
    position: absolute;
    top: 10px;
    right: -145px;
    //height: Vi
    margin-bottom: 28px !important;

    @media(max-width: 767px){
      text-align: start;
    }


    &:last-child{
      margin-bottom: 0px;
    }
    
  }
  .about__text {
    text-align: center;
  }

  @media (max-width: 1200px) {
    //.title {
    //  h2 {
    //    font-size: 90px;
    //    line-height: 90px;
    //  }
    //}
  }

  @media (max-width: 991px) {
    .about__text {
      min-width: 100%;
      margin: 0;
    }

    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 80%;
      }
    }
  }

  @media (max-width: 767px) {

    .mobile{
      justify-content: start !important;
      .dc-btn{
        margin: 40px 0 0 !important;
      }
    }
    h5{
      text-align: start;
    }
    .about__text {
      h3 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }

      p {
        width: 100%;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 0px;
      right: -20px;
      transform: none;
      //display: none;

      svg {
        //width: 40px;
        height: 100px;
      }
    }

    .shadow-left {
      transform: none;
      left: 0;
      bottom: 0px;
      top: auto;
      //display: none;

      svg {
        //width: 30px;
        height: 50px;
      }
    }

    .links {
      margin-top: 50px;
    }
  }


`;

export default MyComponent;
