import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import MediaSingle from "./MediaSingle";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-200 pb-200'}>
            <Container>
                <Row>
                    {
                        data && data?.length>0 &&
                        data?.map((element)=>{
                            return(
                                <Col sm={4} key={element?.ID}>
                                    <MediaSingle
                                        text={element?.hover_title}
                                        title={element?.title}
                                        img={element?.thumb}
                                        link={`/media-center/${element?.post_name}`}
                                        day={element?.date}
                                        month={element?.month}
                                        year={element?.year}

                                    />
                                </Col>
                            )
                        })
                    }


                </Row>
            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
    .col-sm-4{
      margin-bottom: 30px;
    }
`
export default MyComponent;