import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import ReactHtmlParser from "react-html-parser";
import {black} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'make-best parallax'}>
            <div className="make-best__inner">
                {
                    window?.innerWidth>767 ?
                        <Img parallax src={data?.bg ? data?.bg : ''}/> :
                        <Img src={data?.bg ? data?.bg : ''}/>
                }

                <Container>
                    <Row className={'justify-content-center align-items-center'}>
                        <div >
                            <Title text={'WHY US'} margin={'0 0 60px 0'} color={'#EDEEEF'} fontFamily={`${black}`}/>
                        </div>
                    </Row>
                    <div className={'d-flex justify-content-center align-items-center'}>

                    </div>
                    {
                        data?.list && data?.list?.length>0 &&
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                slidesPerGroup={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={900}
                                // infinity={true}
                                pagination={{
                                    clickable: true
                                }}
                                navigation={{
                                    prevEl: '.slider_prev',
                                    nextEl: '.slider_next ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                // autoplay={{
                                //     delay: 4000,
                                //     disableOnInteraction: false,
                                // }}

                                breakpoints={{
                                    1150: {
                                        slidesPerGroup: 3,
                                        slidesPerView: 5,
                                        spaceBetween: 30,
                                        loop: false,
                                    },
                                    950: {
                                        slidesPerGroup: 3,
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },

                                    600: {
                                        slidesPerGroup: 3,
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },


                                }}>

                            {
                                data?.list && data?.list?.length>0 &&
                                data?.list?.map((element,index)=>{
                                    return(
                                        <SwiperSlide key={index}>
                                            <div className="make-best__slider">
                                                <div className="make-best__slider__content">
                                                    <img src={element?.logo} alt=""/>
                                                    <p>{ReactHtmlParser(element?.subtitle)}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }

                </Container>
            </div>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  .make-best__inner {
    position: relative;

    .container {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }

  .make-best__slider {
    background-color: rgb(0 0 0 / 10%);
    backdrop-filter: blur(5px);

    &__content {
      padding-top: calc(250 / 210 * 100%);
      position: relative;

      img {
        position: absolute;
        top: 20px;
        left: 20px;
      }

      p {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
      }
    }
  }

  .swiper {
    padding-bottom: 30px;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: .5;
    height: 5px;
    padding: 0;
    border-radius: 2px;
    width: 20px;
    margin-right: 20px !important;
    @media (max-width: 600px) {
      width: 15px;
      margin-right: 10px !important;
    }

    &.swiper-pagination-bullet-active {
      width: 35px;
      opacity: 1;
      @media (max-width: 600px) {
        width: 25px;
      }
    }
  }

  @media (max-width: 600px) {
    .swiper {
      padding-right: 100px;
    }

    padding-top: 50px;
    .swiper-pagination {
      text-align: left;
    }

    .make-best__inner .container {
      padding-bottom: 90px;
      padding-top: 80px;
    }
  }
`;

export default MyComponent;
