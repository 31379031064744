import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import {grayText, text} from "../../styles/globalStyleVars";
import SliderPrevWhite from "../SliderPrevWhite";
import SliderNextWhite from "../SliderNextWhite";

const MyComponent = ({data}) => {


    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, [data]);



    return (
        <StyledComponent className={`SliderCount pt-200 pb-200`}>

            <Container >
                <Row>
                    <Col md={4} >
                        <div className="slider_wrapper_right">

                            <div className="slider_wrapper_right__init" >
                                <img src={'/images/dynamic/about/icon.svg'}/>

                                <div className="footer">
                                    {
                                        data?.list && data?.list?.length>1 &&
                                        <ul className="slider-nav">
                                            <li onClick={() => slider2?.current?.slickPrev()} className={'slider-prev'}>
                                                <SliderPrevWhite color={'#FFFFFF'}
                                                                 background={`${text}`}
                                                                 hoverBackground={'#F16A11'}
                                                                 hoverColor={'#FFFFFF'}
                                                                 hoverBorder={'#F16A11'}

                                                />
                                            </li>
                                            <li onClick={() => slider2?.current?.slickNext()} className={'slider-next'}>
                                                <SliderNextWhite color={'#FFFFFF'}
                                                                 background={`${text}`}
                                                                 hoverBackground={'#F16A11'}
                                                                 hoverColor={'#FFFFFF'}
                                                                 hoverBorder={'#F16A11'}
                                                />



                                            </li>
                                        </ul>
                                    }


                                </div>
                                {
                                    data?.list && data?.list?.length>0 &&
                                    <Slick asNavFor={nav1}
                                           ref={slider2}
                                           arrows={false}
                                           dots={false}
                                           speed={2000}
                                           ease={''}
                                           slidesToShow={1}
                                           swipeToSlide={true}
                                           focusOnSelect={true}
                                    >
                                        {
                                            data?.list && data?.list?.length>0 &&
                                            data?.list?.map((element)=>{
                                                return(
                                                    <div className="single-item_content">
                                                        {/*<img src={'/images/dynamic/about/icon.svg'}/>*/}
                                                        <h3 className={'split-up'}>{element?.title}</h3>
                                                        <h5 >{element?.description}</h5>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slick>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={{span:7,offset:1}} >
                        <div className="slider-wrapper-left">
                            <div className="slider-wrapper-left__init">
                                {
                                    data?.list && data?.list?.length>0 &&
                                    <Slick
                                        asNavFor={nav2}
                                        fade={true} speed={2000}
                                        arrows={false}
                                        ref={slider1}
                                    >
                                        {
                                            data?.list && data?.list?.length>0 &&
                                            data?.list?.map((element,index)=>{
                                                return(
                                                    <div className="single-item " key={index}>
                                                        <div className="image-wrapper">
                                                            <Img src={element?.image}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slick>
                                }

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: ${grayText};
  position: relative;
  overflow: hidden;

  .footer .slider-nav li:first-child {
    margin-right: 0px !important;
  }
  .slider-next .slider-next{
    margin-left: 20px !important;
  }

  .container, .container-fluid {
    position: relative;
    z-index: 2;
  }


  .slider-wrapper-left {
    &__init {
      @media (max-width: 992px) {
        .slick-list {
          margin-bottom: -8px !important;
        }
      }

      .single-item {
        .image-wrapper {
          padding-top: calc(520 / 670 * 100%);
          position: relative;
        }
      }
    }
  }

  .slider_wrapper_right {
    height: 100%;


    .slider_wrapper_right__init {
      position: relative;
      height: 100%;

      img{
        width: 70px;
        height: 70px;
        margin-bottom: 40px;
      }
      h3 {
       
        color: ${text};
        margin-bottom: 70px;
      }

      .single-item_content {
        img{
          width: 70px;
          height: 70px;
          margin-bottom: 40px;
        }

        h3 {         
          color: ${text};
          margin-bottom: 20px;
        }

        h5 {
          color: ${text};    
        }
      }
    }
  }

  .top_text {
    p {
      font-size: 32px;
      line-height: 44px;
      color: #191818;
      margin-bottom: 60px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;

    .count {
      p {
        color: rgba(247, 244, 244, 0.5);
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
      }

      .current {
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        color: #F7F4F4;
        margin-right: 8px;
      }

      .total {
        color: rgba(247, 244, 244, 0.5);
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
      }


    }

    .slider-nav {
      display: flex;

      li:first-child {
        margin-right: 30px;
      }

      li {
        svg {
          //cursor: pointer;

          #Ellipse_615 {
            transition: 1s all cubic-bezier(.25, .74, .22, .99);
          }

          &:hover {
            #Ellipse_615 {
              opacity: 1;
              stroke-dasharray: 142px;
            }
          }
        }
      }
    }
  }


  //responsive


  @media (max-width: 992px) and (min-width: 768px) {
    .slider-wrapper-left {
      height: 100%;

      .slider-wrapper-left__init {
        height: 100%;

        .slick-slider {
          height: 100%;

          .slick-list {
            height: 100%;

            .slick-track {
              height: 100%;

              .slick-slide {
                height: 100%;

                > div {
                  height: 100%;
                }

                .single-item {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    &:after {
      height: 100px;
      z-index: 0;
    }

    .slider-wrapper-left {
      &__init {
        .slick-list {
          margin-bottom: -8px !important;
        }

        .single-item {
          .image-wrapper {
            height: 100%;


          }
        }
      }
    }

    h3{
      margin-bottom: 40px !important;
    }




    .footer {
      padding: 0;
      margin-bottom: 40px !important;

      position: relative;

      .slider-nav {
        li:first-child {
          margin-right: 15px;
        }

        li {
          svg {
            height: 40px;
            width: 15px;
          }
        }
      }

      .slick-slider {
        margin: 0 0 -10px;
      }
    }

  }
  @media(max-width: 767px){
    .slider-wrapper-left{
      margin-top: 40px;
    }
  }
`;

export default React.memo(MyComponent);
