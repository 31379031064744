import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Detail from "../../components/media/detail";
import MediaSlider from "../../components/media/MediaSlider";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchMediaDetail} from "../../api/redux/newsroom";
import {Helmet, HelmetProvider} from "react-helmet-async";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.media)
    let {slug} = useParams();


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIADETAIL
        dispatch(fetchMediaDetail([api_url + `/${slug}`]))
    }, [])


    //data refactor

    let detailData= getData?.detail?.page_data?.[0];
    let mediaList= getData?.detail?.list;
    let meta= getData?.detail?.page_data?.[0]?.meta;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{'Media Detail | Rancon Real Estate Ltd.'}</title>
            </Helmet>
            <StyledComponent>
                <Detail data={detailData}/>
                <MediaSlider data={mediaList}/>
            </StyledComponent>

        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
`
export default MyComponent;