import {createGlobalStyle, css} from 'styled-components';
import {black, book, grayText, heavy, hover, light, medium, text, title, white} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;

  }

  @font-face {
    font-family: 'Avenir LT Pro 65 black';
    src: url('/fonts/AvenirLTPro-Black.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Pro 65 black';
    src: url('/fonts/AvenirLTPro-BlackOblique.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-BlackOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Pro 45 Book';
    src: url('/fonts/AvenirLTPro-BookOblique.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-BookOblique.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    src: url('/fonts/AvenirLTPro-Book.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    src: url('/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Avenir LT Pro 65';
    src: url('/fonts/AvenirLTPro-MediumOblique.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Pro 55 Roman';
    src: url('/fonts/AvenirLTPro-HeavyOblique.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-HeavyOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir LT Pro';
    src: url('/fonts/AvenirLTPro-Light.woff2') format('woff2'),
    url('/fonts/AvenirLTPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  html {
    /* For Firefox */
    //overflow-y: scroll;
    scrollbar-color: ${text} ${grayText};
    scrollbar-width: thin;
  }


  body {
    font-family: 'Avenir LT Pro', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    cursor: none;
    //
    //&::-webkit-scrollbar {
    //  display: none;
    //}
    //
    //&::-moz-scrollbar {
    //  display: none;
    //
    //}

    //Custom scrollbar

    ::-webkit-scrollbar {
      width: 0.6rem;
    }

    ::-webkit-scrollbar-track {
      overflow: auto;

        background:${grayText};
    }

    ::-webkit-scrollbar-thumb {
        background: ${text};
      border: 3px solid transparent;
      border-radius: 9px;
      background-clip: content-box;

    }

    //-ms-overflow-style: none; /* IE 11 */
    //scrollbar-width: none;
    &.menu-open {
      overflow: hidden;
    }

  }

  /* Ensure scrollbar appears on hover or during scrolling */
  // body:hover::-webkit-scrollbar-thumb {
  //   background: ${text};
  // }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
      cursor: none !important;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  //typography
  h1 {
    font-size: 60px;
    line-height: 68px;
    font-weight: 300;
  }

  h2 {
    font-size: 48px;
    line-height: 56px;
      // font-family: ${light};
    font-weight: 300;
  }

  h3 {
    font-size: 36px;
    line-height: 40px;
      // font-family: ${medium};
    font-weight: 500;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    font-family: ${black};
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  h6 {
      // font-family: ${medium};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    font-weight: 500;
  }


  @media (max-width: 767px) {
    h1 {
      font-size: 36px;
      line-height: 40px;
      font-weight: 900;
    }

    h2 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 900;
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 500 !important;
    }
  }

  p, a, h4, h3, h5, h6 {
    color: ${text};
      // font-family: ${medium};
    margin: 0;
  }

  h1, h2 {
      //font-family: ${title};
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 160px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 160px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 150px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 150px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }


  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .swiper-button-disabled {
    //opacity: 0 !important;
    line {
      stroke: rgba(237, 238, 239, 0.4) !important;
    }
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 2;
    }

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 100%;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        top: 0;
      }
    }
  }

  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }


  //modal
  //  modal

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }

    .modal-dialog {
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
      padding-left: 0px !important;
    }

    .modal-body {
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }

  .modal-close {
    display: flex;
    justify-content: flex-end;
    //margin-top: 50px;
    //margin-bottom: 50px;
    //margin-top: 30px;
    svg {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      position: relative;
      //cursor: pointer;
      @media (max-width: 767px) {
        height: 50px;
        width: 50px;
      }

      #Ellipse_85 {
        transition: 0.7s all ease;
      }

      #Ellipse_86 {
        transition: 0.7s all ease;
      }

      path {
        transition: 0.7s all ease;
      }

      &:hover {
        #Ellipse_86 {
          r: 0px;
          cx: 25px;
          cy: 25px;
        }

        path {
          stroke: #ffffff;
        }
      }

    }
  }


  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${text};
    z-index: 999999999;
    display: none;
    opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 80px;
      opacity: 0;
    }

    @media (max-width: 767px) {
      img {
        height: 150px;
        width: 150px;
      }
    }


  }
  .global-image {
    overflow: hidden;
  }
  .filter {

    &__placeholder {
      color: ${text} !important;
      font-size: 16px;
      font-weight: 500;
    }

    &__control {
      padding-left: 0;
      padding-right: 0;

      &--is-focused {
        background-color: transparent !important;
        border: none;
        border-bottom: 1px solid #FFF;
      }
    }


    &__indicator {
      margin-right: 40px;
      padding: 0;
    }

    &__value-container {
      padding-left: 0;
      padding-right: 0;
      cursor: pointer !important;
    }

  }

  .css-1dimb5e-singleValue {
    color: white !important;
  }

  .css-t3ipsp-control {
    box-shadow: none !important;
    border-color: ${hover} !important;
    border-radius: 22px !important;
  }

  .css-t3ipsp-control:hover {
    border-color: ${hover} !important;
  }

  .css-13cymwt-control {
    background-color: transparent !important;

    &:hover {
      border-color: transparent !important;
      border-bottom: 1px solid #FFFFFF !important;
    }

    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

  }

  .gph_modal .modal-dialog {
    margin: 0px;
    width: 100%;
    height: 100%;
    background: rgb(244, 244, 244);
  }


  .cursor-follower {
    position: fixed;
    background-size: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: translate(2px, 2px);
    visibility: hidden;
    //background-image: url('/images/static/left-arrow-circle.svg');

    &.left-btn {
      background-image: url('/images/static/left-arrow-circle.svg');
    }

    &.right-btn {
      background-image: url('/images/static/right-arrow-circle.svg');
    }

    &.visible-it {
      visibility: visible !important;
    }
  }

  //video modal
  .modal-video {
    background-color: transparent;
    height: 100vh;
    z-index: 99999;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
    }

    .modal-body {
      height: 100vh;
    }

    .modal-content {
      background-color: transparent;

    }

    iframe {
      height: 60vh;
      width: 60vw;
      margin: auto;
      position: absolute;
      inset: 0;
    }

    .close-modal {
      position: absolute;
      top: 40px;
      right: 30px;
      height: 30px;
      //cursor: pointer;
      z-index: 99;
    }

    @media (max-width: 768px) {
      .modal-content {
        //padding: 0 20px;

        iframe {
          width: 90vw;
          height: 60vh;
        }

        .close-modal {
          top: 80px;
          right: 20px;
        }
      }
    }
    @media (max-width: 550px) {
      .modal-content iframe {
        width: 90vw;
        height: 40vh;
      }
    }
  }

  .modal {
    height: 100vh;
    overflow: hidden !important;
    z-index: 9999999;
    padding-right: 0px !important;


    .dc-btn {
      #Contact_Us {
        transform: translate(10px, 15px);
      }
    }

    #Ellipse_18 {
      opacity: 1;
    }

    #Ellipse_19 {
      stroke: ${hover}
    }

    .modal-data__content {
      border-top: 1px solid rgba(60, 60, 59, 0.3);
      //padding-top: 40px;
      padding-left: 0;
    }

    .modal-content {
      background: #56575A;
    }

    &.modal {
      .modal-close {

      }

      .modal-body {
        //padding-top: 50px;
      }

      .modal-data__img {
        ul {
          display: flex;
          margin-top: 20px;

          li {
            display: flex;
            margin-right: 20px;
          }
        }
      }
    }

    .modal-body {
      padding: 0;
    }

    .modal-content {
      border: 0;
    }

    .modal-body {
      background: #56575A;

      &.video {
        background: transparent !important;
        z-index: 999;
      }

      .modal-close {
        position: relative;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        //cursor: pointer;
        //top: -30px;
        right: 0px;
        top: 30px;
        overflow: hidden;


        svg {
          #Ellipse_19 {
            transition: 0.7s all ease;
          }
        }

        &:hover {
          svg {
            #Ellipse_19 {
              opacity: 1;
              stroke-dasharray: 142px;
            }
          }
        }
      }

      .hover:after {
        border-radius: 50%;
      }

      .for-close {

        align-items: flex-end;
        justify-content: flex-end;

      }

      .modal-data {
        margin-top: 80px;
        width: 100%;
        display: flex !important;
        flex-direction: row;
        padding-left: 0;

        p, h5 {
          color: #EDEEEF;
        }

        &__img {

          img {
            object-fit: cover;
            width: 100%;
            margin-bottom: 20px;
          }

          h5 {
            color: #EDEEEF;
          }

          p {
            color: rgba(237, 238, 239, 0.5);
          }
        }

        &__content {
          h5 {
            color: #EDEEEF;
          }

          ul {

            padding-bottom: 20px;
            border-bottom: 1px solid #221F1F;
            margin-bottom: 40px;

            li {
              position: relative;
              padding-left: 25px;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 10px;

              &:after {
                content: '';
                height: 10px;
                width: 10px;
                background-color: ${hover};
                border-radius: 50%;
                left: 0;
                top: 7px;
                //bottom: 0;
                //margin: auto;
                position: absolute;
              }
            }

          }

          p {
            color: #EDEEEF;
            margin-bottom: 20px;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }

        .scroll-div {
          overflow: hidden;
          padding-right: 10px;
          padding-bottom: 10px;
        }
      }
    }

    .modal-dialog {
      width: 90%;
      max-width: unset;
      margin: 1.75rem auto;
      padding: 0;
    }

    .simplebar-track {
      right: -12px;
    }

    .simplebar-track.simplebar-vertical {
      width: 6px;
    }
  }

  @media (max-width: 991px) {
    .modal-dialog {
      width: 95%;
    }
  }

  @media (max-width: 767px) {
    &.modal {
      .modal-close {
        right: 15px !important;
        top: 20px !important;
      }

      .modal-body {
        //padding-top: 50px;
      }
    }

    .modal {
      height: 100% !important;
      overflow-y: auto !important;
      min-height: 100%;

      .col-sm-7 {
        margin: 0;
      }

    }

    .modal-data__img {
      min-width: 100%;

      img {
        max-width: 100%;
      }

    }

    .modal-data__content {
      min-width: 100%;
      margin-top: 40px !important;
    }

    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      //padding: 50px 0;
      padding: 0;
      overflow: hidden;
      height: 100vh;
    }

    .modal-data__content {
      padding-bottom: 60px;
    }

    .modal-body {
      flex: 0;
      padding: 0;

      .footer-social {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (max-width: 767px) {
          display: none;
        }

        ul {
          li {
            width: auto !important;
            min-width: unset;
            max-width: unset;
            margin-right: 20px !important;
          }
        }

        ul {
          display: flex;
          width: 100%;


          li {
            a {
              display: flex;
              height: 25px;
              width: 25px !important;
              border-radius: 50%;
              background-color: #FFF;
              align-items: center;
              justify-content: center;
              color: #ffffff;

              svg {
                position: relative;
                z-index: 2;

                path {
                  //transition: color ;
                }
              }

              &:hover {
                path {
                  fill: #FFF;
                }
              }
            }

            .hover:after {
              background-color: #F16A11;
            }

            &:not(:nth-last-of-type(1)) {
              margin-right: 20px;
            }
          }
        }


        .button-wrap {
          width: 50%;
          @media (min-width: 768px) {
            display: none;
          }

          .dc-btn {
            width: 100%;

            a {
              width: 100% !important;
            }
          }


          img {
            bottom: 0;
          }

          @media (max-width: 767px) {
            //padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            img {
              right: 35px;
            }
          }
        }

        .dc-btn a {
          &:before {
            width: 100%;
          }

          &:after {
            display: none;
          }
        }

        ul {
          margin-top: 30px;
        }
      }


      .modal-data {
        flex-wrap: wrap;
        margin-top: 80px;
      }

      .modal-close {
        right: 15px;
        top: -10px;
      }
    }


  }

  .gph_modal .modal-dialog {

    margin: 0px;
    width: 100%;
    height: 100%;
    background: rgb(244, 244, 244);
  }

  .gph_modal .video {
    margin: 0px;
    width: 100%;
    height: 100%;
    padding-left: 0px !important;

    background: rgb(244, 244, 244);
  }

  .custom-modal-dialog {
  }

  //menu fixed
  .scroll-down .main-menu {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .main-menu {
    background-color: rgba(86, 87, 90, 0.4);
    box-shadow: 0 0 10px rgba(86, 87, 90, 0.5);

    .main-menu__logo a {


      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: flex !important;
      }

    }

    .slide:after {
      background-color: #D6D6D6 !important;
    }

    .hamburger {
      span {
        background-color: white !important;
      }

      h6 {
        color: ${white} !important;
      }
    }

    .main-menu__bar {
      ul {

        path {
          fill: #08082B;
        }
      }
    }
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  //video modal
  .modal-video {
    background-color: transparent;
    height: 100vh;
    z-index: 99999;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
    }

    .modal-body {
      height: 100vh;
    }

    .modal-content {
      background-color: transparent;

    }

    iframe {
      height: 60vh;
      width: 60vw;
      margin: auto;
      position: absolute;
      inset: 0;
    }

    .close-modal {
      //width: 200px;
      height: 200px;
      position: absolute;
      top: 40px;
      right: 30px;
      //height: 30px;
      //cursor: pointer;
      z-index: 1000;
    }

    @media (max-width: 768px) {
      .modal-content {
        //padding: 0 20px;

        iframe {
          width: 90vw;
          height: 60vh;
        }

        .close-modal {
          top: 80px;
          right: 20px;
        }
      }
    }
    @media (max-width: 550px) {
      .modal-content iframe {
        width: 90vw;
        height: 40vh;
      }
    }
  }

  //page loader
  .page-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${text};
    z-index: 999999999;
    display: none;
    opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 80px;
      opacity: 0;
      @media (max-width: 767px) {
        margin-left: 5%;
      }
    }
  }

  //custom cursor

  .cursor-dot,
  .cursor-outline {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 9999999;
  }

  .cursor-dot {
    width: 8px;
    height: 8px;
    background: ${hover};
  }

  .cursor-outline {
    width: 32px;
    height: 32px;
    border: 2px solid ${hover};
    background-color: transparent;
  }

  a:hover ~ .cursor-dot {
    transform: scale(6);
  }


`;



