import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {


    const [imageIndex, setImagerIndex] = useState(1)

    const handleIndex = (e) => {
        setImagerIndex(e + 1)
    }
    const sliderChangeSpeed = 8000;

    useEffect(() => {
        if (data?.length > 0){
            const getAllImg = document.querySelectorAll('.banner-animation image');
            if (document.querySelector('.banner-animation .active')) {
                document.querySelector('.banner-animation .active').classList.remove('active')
            }
            getAllImg[imageIndex - 1]?.classList.add('active')
        }

    }, [imageIndex,data])


    // on hover text add class
    useEffect(() => {
        if (data) {
            const getAlla = document.querySelectorAll('.text-slider');
            // const getAlla = document.querySelectorAll('.banner-animation svg path#Shape-Edge');
            getAlla.forEach(e => {
                e.addEventListener('mouseover', function () {
                    document.querySelector('.home-banner').classList.add('mouse-over')
                });
                e.addEventListener('mouseleave', function () {
                    document.querySelector('.home-banner').classList.remove('mouse-over')
                    // console.log('Mouseover event triggered removed');

                });
            })
        }

    }, [data,imageIndex])



    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        // console.log('swiper',swiper);
        // console.log('speed',speed);
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };


    // handle slider progress
    const handleProgress = (swiper) => {
        let interleaveOffset = 0.5;
        for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress;
            let innerOffset = swiper.width * interleaveOffset;
            let innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };
    const swiperRef = useRef(null);

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    useEffect(() => {
        const swiper = swiperRef.current;

        const autoplayDelay = 5000; // Delay in milliseconds before autoplaying

        const autoplayTimeout = setTimeout(() => {
            if (swiper && swiper.autoplay) {
                swiper.autoplay.start();
            }
        }, autoplayDelay);

        return () => {
            clearTimeout(autoplayTimeout);
        };
    }, [data]);

    return (
        <StyledComponent imageIndex={imageIndex} className={'home-banner'} sliderChangeSpeed={sliderChangeSpeed}>
            {/*home-banner.jpg*/}
            {
                data && data?.length>0 &&
                <div className="home-banner__slider">
                    <Swiper
                        loop={false}
                        spaceBetween={30}
                        slidesPerView={1}
                        allowSlideNext={true}
                        allowSlidePrev={true}
                        allowTouchMove={false}
                        speed={1400}
                        onSetTransition={handleSetTransition}
                        onProgress={handleProgress}
                        touchStart={handleTouchStart}
                        grabCursor={false}
                        lazy={true}
                        watchSlidesProgress={true}
                        mousewheelControl={true}
                        keyboardControl={true}
                        // autoplaySpeed={sliderChangeSpeed}
                        pagination={{
                            clickable: true
                        }}
                        navigation={{
                            prevEl: '.banner-slider_prev',
                            nextEl: '.banner-slider_next ',
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        // autoplay={{
                        //     delay: 8000,
                        //     disableOnInteraction: false,
                        // }}
                        onSlideChange={(e) => handleIndex(e.activeIndex)}
                    >
                        {
                            data && data.length>0 &&
                            data.map((element,index)=>{
                                return(
                                    <SwiperSlide id={index}>
                                        <div className="home-banner__slider__item">
                                            <Img src={element?.image}/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            }
            <div className="text-slider">
                {
                    data && data?.length>0 &&
                    <Swiper loop={false}
                            spaceBetween={30}
                            slidesPerView={1}
                            allowSlideNext={true}
                            allowSlidePrev={true}
                            allowTouchMove={false}
                            speed={600}
                            effect={"fade"}
                            modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            navigation={{
                                prevEl: '.banner-slider_prev',
                                nextEl: '.banner-slider_next ',
                            }}
                    >
                        {
                            data && data.length>0 &&
                            data.map((element,index)=>{
                                return(
                                    <SwiperSlide id={index}>
                                        <div className="text-slider__content">
                                            <h1>{element?.title}</h1>
                                        </div>
                                        {/*<div className="line"/>*/}
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }

            </div>
            <Container>
                {
                    data && data?.length>0 &&
                    <div className={'d-flex justify-content-between'}>
                        <span  >
                            <svg className={'banner-slider_prev'} xmlns="http://www.w3.org/2000/svg" width="72.414" height="22.828" viewBox="0 0 72.414 22.828">
                                <g id="Arrow_-_Left" data-name="Arrow - Left" transform="translate(1.414 1.414)">
                                    <g id="Group_4823" data-name="Group 4823">
                                        <line id="Line_9" data-name="Line 9" x1="10" y2="10" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_10" data-name="Line 10" x2="10" y2="10" transform="translate(0 10)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                    <line id="Line_11" data-name="Line 11" x1="70" transform="translate(0 10)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </span>
                        <span >
                            <svg className={'banner-slider_next'} xmlns="http://www.w3.org/2000/svg" width="72.414" height="22.828" viewBox="0 0 72.414 22.828">
                                <g id="Arrow_-_Right" data-name="Arrow - Right" transform="translate(-587.5 -1590.086)">
                                    <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                                        <line id="Line_9" data-name="Line 9" x2="10" y2="10" transform="translate(-5 -5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_10" data-name="Line 10" x1="10" y2="10" transform="translate(-5 5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                    <line id="Line_11" data-name="Line 11" x2="70" transform="translate(588.5 1601.5)" fill="none" stroke="#edeeef" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </span>
                    </div>
                }
            </Container>
            <div className="banner-animation">
                <>
                    {
                        window?.innerWidth>767 ?
                            <svg id="Animation_-_Banner" data-name="Animation - Banner" xmlns="http://www.w3.org/2000/svg" width="1366" opacity='0.5' height="768" viewBox="0 0 1366 768">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_6305" data-name="Rectangle 6305" width="1366" height="768" fill="#002856"/>
                                    </clipPath>
                                    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0"/>
                                        <stop offset="0.5" stop-opacity="0"/>
                                    </linearGradient>
                                    <clipPath id="clip-path-3">
                                        <path id="Mask"
                                              d="M267.782,303.747l-208.531,384H91.807l175.975-323.9,160.4,295.269H395.118L267.782,424.644l-142.913,263.1h97.793l45.119-83.008L312.9,687.747h32.566L267.782,544.739,205.674,659.112H173.013l94.769-174.467,110.251,203.1h98.28Z"
                                              transform="translate(414.749 -111.747)" fill="#fff"/>
                                    </clipPath>
                                    <clipPath id="clip-path-4">
                                        <rect id="Rectangle_6308" data-name="Rectangle 6308"
                                              width="1692" height="952" transform="translate(-0.025 -0.309)" fill="#002856"/>
                                    </clipPath>
                                    <pattern id="pattern" width="100%" height="100%" patternTransform="matrix(-1, 0, 0, 1, 2730.667, 0)" viewBox="0 0 1365.333 768">
                                        {/*//for right side image*/}
                                        {/*<use preserveAspectRatio="none" xlinkHref={`#a0`}/>*/}
                                        {/*<use preserveAspectRatio="none" xlinkHref="#a1"/>*/}
                                        {/*<use preserveAspectRatio="none" xlinkHref="#a2"/>*/}
                                    </pattern>
                                    {
                                        data && data.length>0 &&
                                        data.map((element,index)=>{
                                            return(
                                                <image id={`a${index}`} preserveAspectRatio="none" width="100%" height="100%" xlinkHref={element?.image}/>
                                            )
                                        })
                                    }

                                    {/*<image id={`a2`} preserveAspectRatio="none" width="100%" height="100%" xlinkHref={'/images/dynamic/home/banner_image.jpg'}/>*/}
                                    {/*<image id={`a1`} preserveAspectRatio="none" width="100%" height="100%" xlinkHref={'/images/dynamic/home/banner2.jpg'}/>*/}

                                    {/*<image id="a1" preserveAspectRatio="none" width="100%" height="100%" xlinkHref="/images/dynamic/home/banner1.jpg"/>*/}
                                    {/*<image id="a2" preserveAspectRatio="none" width="100%" height="100%" xlinkHref="/images/dynamic/home/banner2.jpg"/>*/}
                                </defs>


                                {/*<g id="Base_Image" data-name="Base Image" clip-path="url(#clip-path)" >*/}
                                {/*    <g id="Image" clip-path="url(#clip-path)">*/}
                                {/*        /!*<use id="Final_03" data-name="Final 03" xlinkHref="#image-3"/>*!/*/}
                                {/*        <rect id="Rectangle_6236" data-name="Rectangle 6236" width="2208" height="1523.8" transform="matrix(0.819, -0.574, 0.574, 0.819, -550.951, 284.842)" opacity="0.5" fill="url(#linear-gradient)"*/}
                                {/*              style={{mixBlendMode: "overlay", isolation: "isolate"}}/>*/}
                                {/*        <rect id="Rectangle_6237" data-name="Rectangle 6237" width="2208" height="1268.8" transform="translate(2118.151 287.995) rotate(152)" opacity="0.45" fill="url(#linear-gradient)"/>*/}
                                {/*    </g>*/}
                                {/*</g>*/}
                                <path id="Shape-Edge" d="M267.782,303.747l-208.531,384H91.807l175.975-323.9,160.4,295.269H395.118L267.782,424.644l-142.913,263.1h97.793l45.119-83.008L312.9,687.747h32.566L267.782,544.739,205.674,659.112H173.013l94.769-174.467,110.251,203.1h98.28Z" transform="translate(417.749 -108.747)" fill="#fff"
                                      style={{mixBlendMode: "difference", isolation: "isolate",
                                          boxShadow: " 0px 0px 19px,#FFFFFF"
                                      }}/>
                                {/*<path id="Shape-Edge" d="M267.782,303.747l-208.531,384H91.807l175.975-323.9,160.4,295.269H395.118L267.782,424.644l-142.913,263.1h97.793l45.119-83.008L312.9,687.747h32.566L267.782,544.739,205.674,659.112H173.013l94.769-174.467,110.251,203.1h98.28Z" transform="translate(417.749 -108.747)" stroke="#fff" strokeWidth={'2'} fill={'none'}*/}
                                {/*      style={{mixBlendMode: "difference", isolation: "isolate"}}/>*/}
                                {

                                    <g id="Shape" clip-path="url(#clip-path-3)">
                                        <g id="Animated_Image" data-name="Animated Image" transform="translate(-162.976 -91.691)" clip-path="url(#clip-path-4)">

                                            {
                                                data && data.length>0 &&
                                                data.map((element,index)=>{
                                                    return(
                                                        <use data-name="Final 03" transform="translate(-393.024 -221.309) scale(1.815 1.815)" xlinkHref={`#a${index}`}/>
                                                    )
                                                })
                                            }
                                            {/*<use data-name="Final 03" transform="translate(-393.024 -221.309) scale(1.815 1.815)" xlinkHref={`#a0`}/>*/}
                                            {/*<use data-name="Final 03" transform="translate(-393.024 -221.309) scale(1.815 1.815)" xlinkHref="#a1"/>*/}
                                            {/*<use  data-name="Final 03" transform="translate(-393.024 -221.309) scale(1.815 1.815)" xlinkHref="#a2"/>*/}
                                            <rect id="Rectangle_6306" data-name="Rectangle 6306" width="2734.977" height="1887.481" transform="matrix(0.819, -0.574, 0.574, 0.819, -682.478, 352.867)" opacity="0.2" fill="url(#linear-gradient)"
                                                  style={{mixBlendMode: "overlay", isolation: "isolate"}}/>
                                            <rect id="Rectangle_6307" data-name="Rectangle 6307" width="2734.977" height="1571.621" transform="translate(2623.649 356.773) rotate(152)" opacity="0.45" fill="url(#linear-gradient)"/>
                                        </g>
                                        <g id="Static_Image" data-name="Static Image" transform="translate(683 -384)" clip-path="url(#clip-path)">
                                            <rect id="Final_03-3" data-name="Final 03" width="1365.333" height="768" transform="translate(0)" fill="url(#pattern)"/>
                                            <rect id="Rectangle_6309" data-name="Rectangle 6309" width="2208" height="1523.8" transform="matrix(0.819, -0.574, 0.574, 0.819, -550.951, 284.842)" opacity="0.7" fill="url(#linear-gradient)"
                                                  style={{mixBlendMode: "overlay", isolation: "isolate"}}/>
                                            <rect id="Rectangle_6310" data-name="Rectangle 6310" width="2208" height="1268.8" transform="translate(2118.151 287.995) rotate(152)" opacity="0.45" fill="url(#linear-gradient)"/>
                                        </g>
                                        <path id="Overlay" d="M267.782,303.747l-208.531,384H91.807l175.975-323.9,160.4,295.269H395.118L267.782,424.644l-142.913,263.1h97.793l45.119-83.008L312.9,687.747h32.566L267.782,544.739,205.674,659.112H173.013l94.769-174.467,110.251,203.1h98.28Z" transform="translate(414.749 -111.747)" fill="#fff" opacity="0.4"
                                              style={{mixBlendMode: "overlay", isolation: "isolate"}}/>
                                    </g>
                                }


                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg"  width="345" height="320.65" viewBox="0 0 345 320.65">
                                <defs>
                                    <clipPath id="clip-path">
                                        <path id="Mask" d="M231.751,303.747,59.251,621.4H86.182L231.751,353.459,364.434,597.71H337.085L231.751,403.755,113.532,621.4h80.9l37.323-68.665L269.074,621.4h26.939L231.751,503.1,180.374,597.71H153.356l78.395-144.322,91.2,168.01h81.3Z" transform="translate(414.749 -111.747)" fill="#fff"/>
                                    </clipPath>
                                    <clipPath id="clip-path-2">
                                        <rect id="Rectangle_6308" data-name="Rectangle 6308" width="1399.647" height="787.508" transform="translate(0 0)" fill="#002856"/>
                                    </clipPath>
                                    <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 7360 4912">

                                        {
                                            data && data.length>0 &&
                                            data.map((element,index)=>{
                                                return(
                                                    <image id={`a${index}`} width="7360" height="4912" xlinkHref={element?.image}/>
                                                // <image id={`a0`}  xlinkHref={'/images/dynamic/home/banner1.jpg'}/>

                                            )
                                            })
                                        }
                                        {/*<image id={`a0`} width="7360" height="4912" xlinkHref={'/images/dynamic/home/banner1.jpg'}/>*/}
                                        {/*<image id={`a2`} width="7360" height="4912" xlinkHref={'/images/dynamic/home/banner_image.jpg'}/>*/}
                                        {/*<image id={`a1`} width="7360" height="4912" xlinkHref={'/images/dynamic/home/banner2.jpg'}/>*/}
                                    </pattern>
                                    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0"/>
                                        <stop offset="1" stop-opacity="0"/>
                                    </linearGradient>
                                </defs>
                                <g id="Group_24098" data-name="Group 24098" transform="translate(-15977.469 -110)">
                                    <path id="Shape-Edge" d="M231.751,303.747,59.251,621.4H86.182L231.751,353.459,364.434,597.71H337.085L231.751,403.755,113.532,621.4h80.9l37.323-68.665L269.074,621.4h26.939L231.751,503.1,180.374,597.71H153.356l78.395-144.322,91.2,168.01h81.3Z" transform="translate(15918.218 -190.747)" fill="#fff"
                                          style={{mixBlendMode: "overlay", isolation: "isolate"}}/>
                                    <g id="Shape" transform="translate(15503.469 -82)" clip-path="url(#clip-path)">
                                        <g id="Animated_Image" data-name="Animated Image" transform="translate(-52.936 -42.929)" clip-path="url(#clip-path-2)">
                                            <rect id="_NKM7645" width="1550.741" height="1034.125" transform="translate(69.066 -160.196)" fill="url(#pattern)"/>
                                            <rect id="Rectangle_6306" data-name="Rectangle 6306" width="2262.413" height="1561.352" transform="matrix(0.819, -0.574, 0.574, 0.819, -564.536, 292.152)" opacity="0.7" fill="url(#linear-gradient)"
                                                  style={{mixBlendMode: "overlay", isolation: "isolate"}}/>
                                            <rect id="Rectangle_6307" data-name="Rectangle 6307" width="2262.413" height="1300.068" transform="translate(2170.342 295.383) rotate(152)" opacity="0.45" fill="url(#linear-gradient)"/>
                                        </g>
                                        <path id="Overlay" d="M231.751,303.747,59.251,621.4H86.182L231.751,353.459,364.434,597.71H337.085L231.751,403.755,113.532,621.4h80.9l37.323-68.665L269.074,621.4h26.939L231.751,503.1,180.374,597.71H153.356l78.395-144.322,91.2,168.01h81.3Z" transform="translate(414.749 -111.747)" fill="#fff" opacity="0.4"
                                              style={{mixBlendMode: "overlay", isolation: "isolate"}}/>
                                    </g>
                                </g>
                            </svg>
                    }
                </>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #000;

  .home-banner__slider {
    height: 100vh;
    width: 100vw;
    position: relative;


    .swiper {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 5;
      overflow: hidden;
    }

    .swiper-slide {
      overflow: hidden;

    }

    &__item {
      overflow: hidden;
      transform: scale(1.3);
      width: 100%;
      height: 100%;
      transition: all 2.5s ease;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 5;
        filter: brightness(70%);
        transition: all .6s cubic-bezier(0.4, 0, 0, 1);
        top: 0;
        left: 0;
        object-fit: cover;
        right: 0;
        bottom: 0;

      }
    }
  }


  .container {
    position: absolute;
    display: flex;
    align-items: center;
    //z-index: 9;
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: 0;
    top: 0px;
    span{
      transform: translateX(0);
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
      z-index: 99999999 !important;
      width: 80px;
      height: 80px;
      @media(min-width: 768px){
        &:first-child{
          &:hover {
            transform: translateX(-20px);
          }
        }
        &:last-child{
          &:hover {
            transform: translateX(20px);
          }
        }
      }

      @media(max-width: 767px){
        display: flex;
        align-items: end;
      }

    }


    //svg{
    //  z-index: 999999999999999 !important;
    //}
    div {
      width: 100%;
    }
  }

  .banner-animation {
    height: 600px;
    width: 600px;

    z-index: 9;
    left: 0px;
    right: 70px;
    margin: auto;
    bottom: 0;
    top: 50px;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      //height: 85%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      margin: auto;
      //background-color: red;
      transition: all .6s cubic-bezier(0.4, 0, 0, 1);

      image {
        //width: 1440px;
        //height: 782px;
        object-fit: cover;
        //display: none;
        transform: translateX(-50px);
        transition: transform .6s ease, visibility 0s, opaicty .6s ease;
        transform-origin: center;
        visibility: hidden;

        &:nth-of-type(${p => p.imageIndex}) {
          visibility: visible;
        }

        &.active {
          transform: none;
        }
      }

      #Mask-2 {
        //fill: #ffffff26
        fill: red;
      }
    }
    svg path#Shape-Edge{

    }
  }


  .text-slider {
    position: absolute;
    height: 80%;
    width: 80%;
    z-index: 99;
    inset: 0;
    margin: auto;

    .line {
      top: 0;
      bottom: -590px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
      position: absolute;
      width: 60%;
      height: 10px;


      &:before {
        content: '';
        top: 0;
        bottom: 0px;
        left: 0;
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 1px;
        opacity: 0.5;
        background-color: #ffffff;
      }

      &:after {
        content: '';
        width: 0;
        height: 1px;
        background-color: #ffffff;
        box-shadow: 0px 0px 15px #ffffff;
          // background-color: ${hover};
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0;
        z-index: 3;
        opacity: 1;
        height: 3px;
        transition: width 2000ms cubic-bezier(0.4, 0, 0, 1);
        //transition: width 2000ms cubic-bezier(0.4, 0, 0, 1);
      }
    }


    .swiper {
      height: 100%;
      width: 100%;
      position: absolute;
    }

    &__content {
      position: absolute;
      height: fit-content;
      width: fit-content;
      inset: 0;
      margin: auto;
      z-index: 9;
      text-align: center;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 22;
        left: 0;
        top: 0;

      }

      h1 {

        color: #ffffff;
        text-transform: uppercase;
        //margin-bottom: 20px;
        opacity: 0;
        //z-index: 999999999999;
        transform: translateY(30px);
        transition: .5s ease;
        transition-delay: 0s;
      }

      p {
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        opacity: 0;
        transform: translateY(30px);
        transition: .5s ease;
        transition-delay: 0s;
      }

    }
    @media(max-width: 767px){
      width: 100%;
      .line{
        width: 90% !important;
      }
    }
    .swiper-slide-active {
      .line{
        &:after {
          width: 100% !important;
        }
      }
    }

  }
  .swiper-pagination {
    //display: none;
    bottom: 40px;
    @media (max-width: 767px) {
      bottom: 100px;
      width: 94%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 10px;
    }
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: .5;
    height: 3px;
    padding: 0;
    border-radius: 0px;
    width: 100px;
    margin-right: -4px !important;
    border: 1px solid transparent;
    box-shadow: 0px 0px 15px #ffffff;

    //outline: none !important;
    @media (max-width: 600px) {
      width: 100%;
      &:last-child{
        margin-right: 0px !important;
      }


    }

    &.swiper-pagination-bullet-active {
      //width: 35px;
      opacity: 1;
      @media (max-width: 600px) {
        //width: 25px;
      }
    }
  }

  .swiper-slide-active {


    .home-banner__slider__item {
      transform: scale(1);
      //transition: 1.8s ease;
    }


    .text-slider__content {
      h1 {
        opacity: 1;
        transform: none;
        //transition: .6s ease !important;
        transition-delay: .6s;
        font-weight: 900;

      }
      &.line:after {
        width: 100%;
      }
      p {
        opacity: 1;
        transform: none;
        //transition: .6s ease !important;
        transition-delay: .8s;
      }


    }

  }

  //on hover animation 
  &.mouse-over {

    .text-slider h1{
      letter-spacing: 6px;
    }

    .home-banner__slider img {
      //transform: scale(1.05);
      z-index: 999999999;
      transition: .6s cubic-bezier(0.4, 0, 0, 1) !important;
    }
    


    .banner-animation {
      svg {
        opacity: 0.8;

        image {
          transform: scale(.65) !important;
          -moz-transform: scale(.65) !important;
          z-index: 999999999 !important;
        }
      }
    }
    @media(min-width: 1800px){
      .banner-animation {
        svg {
          image {
            transform: scale(.55);
          }
        }
      }
    }
    @media(max-width: 767px){
      .banner-animation {
        svg {
          image {
            transform: scale(0.70);
          }
        }
      }
    }

  }

  @keyframes bouncing {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 10px, 0);
    }
  }

  @media (max-width: 767px){
    .container{
      align-items: end;
      bottom: 40px;
      div{
        justify-content: start !important;
      }
      .banner-slider_next{
        margin-left: 20px;
      }
    }
    .text-slider__content{
      text-align: start;
    }
  }

  @media (max-width: 650px) {
    .text-slider__content {
      width: calc(95% - 30px);
    }

    .banner-animation svg {
      width: 80%;
      height: 80%;
      margin: auto;
      overflow: hidden;
    }
  }
  @media (max-width: 615px) {

    .banner-animation {
      display: block;
    }

    .banner-animation svg {
      width: 80%;
      height: 80%;
      left: 0%;
      margin: auto;
      overflow: hidden;
    }

    .text-slider__content {
      h2 {
        font-size: 42px;
        line-height: 35px;
      }

      p {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .go-down {
      left: 15px;
    }

  }



`;

export default MyComponent;
