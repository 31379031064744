import React, { useEffect, useState } from 'react';
import styled from "styled-components";
// import a from '../../public/images/dynamic/project/map.jpg'
import {Img} from "./Img";
import {Col, Row} from "react-bootstrap";
const MyComponent = ({data}) => {

    return (
        <StyledComponent>
            <Row>
                <Col md={12}>
                    <div className="map-image">
                        <a href={data?.url} target="_blank">
                            <Img src={data?.image}/>
                        </a>
                    </div>
                </Col>
            </Row>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .map-image{
    position: relative;
    padding-top: calc(660 / 1366 * 100%);
  }
`
export default MyComponent;