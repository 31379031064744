import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        },
        loaded: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAbout/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContact/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchLandowner/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMedia/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProject/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        // ['fetchDataHome/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchContact/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchInvest/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchVoices/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchTakaTalks/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchPlaybook/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchRoom/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchFAQ/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchInvestorServices/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchMutualFund/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchFindUs/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchFund/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchPlan/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // },
        // ['fetchCalculator/fulfilled']: (state, action) => {
        //     state.globalLoader = false
        // }
    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
