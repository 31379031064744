import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Table} from "react-bootstrap";
import {
    InstagramShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton, InstapaperIcon
} from "react-share";
import Sticky from 'react-stickynode';
import reactHtmlParser from "react-html-parser";
import {grayText, hover, text} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import {Link} from "react-router-dom";
import Button from "../Button";
import {gsap} from "gsap";
import ReactHtmlParser from "react-html-parser";
import SliderPrevWhite from "../SliderPrevWhite";
import Modal from "react-bootstrap/Modal";

const MyComponent = ({data}) => {

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const [show, setShow] = useState(false);
       const handleClose = () => {
        setShow(false);
    }

    let banner=data?.banner;
    let description=data?.description;
    let images=data?.images;
    let qoute=data?.qoute;
    let slider_list=data?.slider_list;
    let table=data?.table;
    let video=data?.video;
    let conclusion=data?.conclusion;

    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])

    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    //sticky

    useEffect(() => {
        if(window?.innerWidth>767){
            gsap.to(".detail-page__text-content__detail", {
                scrollTrigger: {
                    trigger: ".detail-page__text-content__share",
                    start: "top top",
                    endTrigger: ".detail-page__text-content__detail",
                    end: "bottom-=250px top",
                    pin: true,
                    pinSpacing: false
                }
            });
        }
    }, []);





    return (
        <StyledComponent className={'detail-page'}>
            <div className={'detail-page__img parallax'}>
                <Img parallax src={banner?.image}/>
            </div>
            <Container className={'detail-page__text-content'}>
                <Row>
                    <Col sm={2} className={'detail-page__text-content__share'}>
                        <div>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#56575A"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg id="layer1" xmlns="http://www.w3.org/2000/svg" width="11.035" height="10.328" viewBox="0 0 11.035 10.328">
                                        <path id="path1009" d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z" transform="translate(-281.5 -167.31)" fill="#56575a"/>
                                    </svg>

                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.186" height="11.186" viewBox="0 0 11.186 11.186">
                                        <g id="Group_13724" data-name="Group 13724" transform="translate(-2167 -9029)">
                                            <path id="Path_5912" data-name="Path 5912" d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0" transform="translate(2167 9029)" fill="#56575a"/>
                                            <path id="Path_5913" data-name="Path 5913" d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864" transform="translate(2149.045 9011.045)" fill="#56575a"/>
                                            <path id="Path_5914" data-name="Path 5914" d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671" transform="translate(2114.82 9016.224)" fill="#56575a"/>
                                        </g>
                                    </svg>

                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#56575A"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                                <li className={'hover'}>
                                    <svg id="Group_31" data-name="Group 31" xmlns="http://www.w3.org/2000/svg" width="9.97" height="6.979" viewBox="0 0 9.97 6.979">
                                        <path id="Path_29" data-name="Path 29" d="M1176.069,3919.422c-.36-.425-1.025-.6-2.294-.6h-4.606c-1.3,0-1.974.185-2.333.638a3.24,3.24,0,0,0-.349,1.993v1.717c0,1.745.415,2.631,2.682,2.631h4.606a2.913,2.913,0,0,0,2.1-.529,2.759,2.759,0,0,0,.577-2.1v-1.717A3.158,3.158,0,0,0,1176.069,3919.422Zm-3.181,3.128-2.092,1.087a.322.322,0,0,1-.47-.284v-2.168a.32.32,0,0,1,.154-.273.325.325,0,0,1,.315-.011l2.092,1.081a.319.319,0,0,1,0,.568Z" transform="translate(-1166.487 -3918.823)" fill="#56575a"/>
                                    </svg>

                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                            </ul>
                        </div>
                        <div className={'mobile-share'}>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#56575A"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#56575A"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#56575A"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                            </ul>
                        </div>

                    </Col>
                    <Col sm={10} className={'detail-page__text-content__detail'}>
                        <div className={'full-detail'}>
                            <div className={'detail-page__text-content__detail__date '}>
                                <p className={'split-up'}>Blog</p>
                                <p className={'split-up'}>{banner?.date}</p>
                            </div>
                            <h1 className={'split-up'}>
                                {data?.post_title}
                            </h1>
                            {ReactHtmlParser(description?.detail)}
                            <br/>
                            <br/>

                            <img src={table?.image}/>
                            {ReactHtmlParser(table?.description)}
                            <br/>
                            <br/>

                            <div className="quotes split-up">
                                {ReactHtmlParser(qoute?.description)}
                            </div>
                            <br/>

                            {
                                video?.thumb &&
                                <div className="video-box" onClick={() => handelOpen(true,'')} >
                                    <div className="hover-btn hover">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
                                            <path id="Polygon_2" data-name="Polygon 2" d="M12,0,24,18H0Z" transform="translate(18) rotate(90)" fill="#f16a11"/>
                                        </svg>

                                    </div>
                                    <img src={video?.thumb} alt=""/>
                                </div>
                            }

                            <h5 className={'split-up'}>{video?.title}</h5>
                            <p className={'split-up'}>{video?.description}</p>
                            <br/>
                            <br/>
                            <img src={images?.top}/>
                            <Row className={'mt-4'}>
                                <Col md={6}>
                                    <img src={images?.left}/>
                                </Col>
                                <Col md={6}>
                                    <img src={images?.right}/>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <h5 className={'split-up'}>{conclusion?.title}</h5>
                            <p className={'split-up'}>{conclusion?.description}</p>
                        </div>

                    </Col>
                </Row>
            </Container>
            {/*modal*/}
            {
                video?.url &&
                <Modal className={'modal-video'} show={show} onHide={handleClose}>

                    <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="140"
                         height="140"
                         viewBox="0 0 140 140">
                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                              d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                              transform="translate(-11.285 -11.289)" fill="#fff"/>
                    </svg>


                    <Modal.Body className={'video'}>
                        <iframe  width="560" height="315"
                                 src={`https://www.youtube.com/embed/${video?.url}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
                                 title="YouTube video player" frameBorder="0"
                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                 allowFullScreen/>
                    </Modal.Body>

                </Modal>

            }


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #EDEEEF;

  .full-detail {
    border-bottom: 1px solid ${text};
    padding-bottom: 60px;
  }

  .detail-page__img {
    position: relative;
    padding-top: calc(600 / 1366 * 100%);
    @media (max-width: 767px) {
      padding-top: calc(560 / 375 * 100%);
    }
  }
  h5{
    margin-bottom: 20px;
  }


  .detail-page__heading {
    h1 {
      font-size: 32px;
      line-height: 36px;
      color: #ED5333;
      font-weight: 600;
    }

    &__tags {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        color: ${hover};
        font-size: 16px;
        line-height: 24px;
      }

      ul {
        display: flex;

        li {

          font-size: 16px;
          line-height: 24px;
          color: ${hover};

          &:not(:nth-last-child(1)) {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 0;
            border-right: 1px solid #CED7ED;
          }
        }
      }
    }

  }

  .detail-page__banner {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .hover:after {
    border-radius: 50%;
  }

  .detail-page__text-content {
    padding-top: 40px;

    &__share {
      .mobile-share {
        display: none;
      }


      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        }

      }
      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
          display: none;

        }

        .sticky-outer-wrapper {
          display: none;
        }
      }


      p {
        color: #56575A;
        margin-bottom: 20px;
        font-weight: 500 !important;
        text-transform: uppercase;
      }

      ul {
        li {
          min-height: 30px;
          height: 30px;
          width: 30px;
          min-width: 30px;
          border-radius: 50%;
          background-color: ${grayText};
          display: flex;
          align-items: center;
          justify-content: center;
          //cursor: pointer;
          margin-bottom: 20px;
          position: relative;

          button {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
          }

          svg {
            position: relative;
            z-index: 2;
          }
        }
      }

      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between !important;
      }
    }

    &__detail {
      p{
        letter-spacing: 0;
      }
      .custom-list {
        padding: 0;

        li {

          position: relative;
          padding-left: 20px;
          //padding-top: 20px;
          &:not(:last-child) {
            padding-bottom: 0px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: -5px;
            width: 8px;
            height: 8px;
            box-sizing: content-box;
            background-color: ${hover};
          }
        }

      }

      img {
        width: 100%;
      }

      span {
        p {
          color: ${hover};
          font-weight: 500;
          margin-top: 20px;
        }
      }

      &__date {
        display: flex;
        justify-content: space-between;


        p {
          font-weight: 500 !important;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px !important;
          text-transform: uppercase;
        }

        @media (max-width: 767px) {
          display: block;
          p {
            &:not(last-child) {
              margin-bottom: 10px !important;
            }
          }
        }
      }

      h1 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 500;
        padding-bottom: 60px;
        border-bottom: 1px solid #56575A;
        margin-bottom: 60px;

        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 40px;
          margin-top: 40px;
        }
      }

      h2 {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      p {
        font-weight: 400;
      }

      .quotes {
        padding-left: 40px;
        position: relative;
        margin-bottom: 40px;

        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: ${hover};
          border-radius: 5px;
        }

        h6 {
          margin-top: 30px;
          font-weight: 500;
          color: ${text};
        }
      }

      table {
        min-width: 100%;
        margin-bottom: 30px;


        th {
          color: ${hover};
          border-bottom: 1px solid ${text};
          padding: 20px 0;
          font-weight: 500 !important;
        }

        td {
          padding: 20px 0;
          border-bottom: 1px solid rgba(86, 87, 90, 0.2);

          &:nth-of-type(1) {
            padding-right: 200px;
            @media (max-width: 767px) {
              padding-right: 30px;
            }
          }
        }
      }

      .custom-list {
        padding: 0;

        li {

          position: relative;
          padding-left: 20px;
          padding-top: 20px;

          &:not(:last-child) {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(86, 87, 90, 0.2);
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: 13px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-sizing: content-box;
            background-color: ${hover};
          }
        }

      }

      .video-box {
        position: relative;
        //cursor: pointer;
        margin-top: 30px;
        width: 100%;
        margin-bottom: 60px;

        img {
          width: 100%;
          
        }

        .hover-btn {
          height: 80px;
          width: 80px;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;

          &.hover:after {
            border-radius: 50%;
          }

          svg {
            position: relative;
            z-index: 2;
          }

          path {
            transition: all .3s ease;
          }
          &:hover {

            &:after {
              height: 100%;
              width: 100%;
            }

            path {
              fill: #FFF;
            }
          }

        }


      }


    }

  }


  @media (max-width: 767px) {

    //margin-bottom: 80px;
    .detail-page__heading__tags {
      flex-wrap: wrap;

      ul {
        min-width: 100%;
        margin-bottom: 20px;
      }

      p {
        min-width: 100%;
      }
    }

    .detail-page__text-content__share {
      margin-bottom: 60px;
      min-width: 100%;

      ul {
        display: flex;

        li {
          margin-right: 10px;
        }
      }
    }

    .detail-page__text-content__detail {
      min-width: 100%;
    }
  }
  //.sticky-inner-wrapper{
  //  top:120px !important;
  //}
  @media (max-width: 767px) {
    .title h2 {
      text-align: start !important;
    }
  }
`;

export default MyComponent;
