import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import contactReducer from '../redux/contacts'
import mediaReducer from '../redux/newsroom'
import landownerReducer from '../redux/landowner'
import projectReducer from '../redux/project'


const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        home: homeReducer,
        about: aboutReducer,
        landowner: landownerReducer,
        contact : contactReducer,
        media : mediaReducer,
        project : projectReducer,
    },
})

export default store

