import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {body, ease, hover, text, title, whites} from "../styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import CloseButton from "./CloseButton";
import Select, {components} from "react-select";
import SimpleBar from "simplebar-react";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {ApiParam} from "../api/network/apiParams";
import Button from "./Button";

const MyComponent = () => {
    const dispatch = useDispatch()
    const getProductData = useSelector(store => store.projectReducer)
    gsap.registerPlugin(CSSPlugin);
    // offset
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 16)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 16)
        })

        // // menu click on mobile

        function calculateHeight() {
            // let vh = window.innerHeight * 0.01;
            // document?.documentElement?.style.setProperty('--vh', `${vh}px `);
            // let menuCloseAction = document?.querySelector('.slide-menu');
            // if (menuCloseAction) {
            //     menuCloseAction.style.height = `${vh * 100}px`;
            //     console.log(menuCloseAction);
            // }
        }
        calculateHeight()
        window.addEventListener('resize', calculateHeight);
        // to stop scrolling after clicking the menu button
    }, [])

    const location = useLocation()

    // menu action
    useEffect(() => {
        // menu open
        let getMenu = document.querySelector('.slide-menu');
        let getClick = document.querySelector('.hamburger');
        let getCloseClick = document.querySelector('.slide-menu__close');
        let tl = gsap.timeline()
        getClick.addEventListener('click', function (e) {
            e.stopPropagation(); // Prevent the event from propagating

            document.body.classList.add('menu-open')
            if (window.innerWidth > 600) {
                setTimeout(() => {
                    disableBodyScroll(e)
                }, 300)
            }


            tl.to(getMenu, {
                display: 'flex',
                duration: 0
            }).to('.menu-overlay', {
                display: 'block',
                duration: 0,
            }, '-=.3').to('.menu-overlay', {
                opacity: 1
            }).to(getMenu, {
                x: 0,
                // duration: 1,
                ease: 'Power4.easeInOut'
            }, '-=.5').to('.slide-menu li,.accordion-item ', {
                y: 0,
                opacity: 1,
                ease: 'Power4.easeInOut',
                stagger: .02
            }, '-=.3')
        });




        // menu close
        window.addEventListener('click', (e) => {
            if (document.body.classList.contains('menu-open')) {
                // e.stopPropagation(); // Prevent the event from propagating

                if (!e.target.matches('.search-action,.search-action img, .hamburger,.hamburger span, .accordion-header, .accordion-header button,.accordion-header span, .accordion-header img, .accordion-collapse , .accordion-collapse div, .accordion-collapse ul, .accordion-collapse li,.slide-menu .bg-noise,.slide-menu ul .accordion li') ) {

                    if (window.innerWidth > 600) {
                        setTimeout(() => {
                            enableBodyScroll(e)
                        }, 300)
                    }
                    tl.to('.slide-menu li,.accordion-item ', {
                        y: 20,
                        opacity: 0,
                        ease: 'Power4.easeInOut',
                        stagger: .01,
                        duration: .4
                    }).to(getMenu, {
                        x: '100%'
                    }, '-=.3').to(getMenu, {
                        display: 'node',
                        duration: 0
                    }).to('.menu-overlay', {
                        display: 'none',
                        opacity: 0
                    }, '-=.3')
                    document.body.classList.remove('menu-open')
                }
            }
        })


    }, [])


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);



    return (
        <StyledComponent className={'main-menu'} >
            <div className="menu-overlay"/>

            {/*menu bar */}
            <Container>
                <div className="main-menu__bar">
                    <Row>
                        {
                            window.innerWidth > 767 ?
                                <Col md={5}>
                                    <div className={'buttons'}>
                                        <Button src={'/projects'} text={'EXPLORE PROJECTS'} color={'#56575A'} background={'#FFFFFF'} hoverColor={'#FFFFFF'} hoverBackground={'#F16A11'} hoverBorder={'#F16A11'} />
                                        <Button src={'/about'} text={'ABOUT US'} color={'#56575A'} background={'#FFFFFF'} hoverColor={'#FFFFFF'} hoverBackground={'#F16A11'} hoverBorder={'#F16A11'}/>
                                    </div>
                                </Col> : ''
                        }

                        <Col md={{span:2}}>
                            <div className="main-menu__logo">
                                <Link to={'/'}><img src="/images/static/logo.svg" alt=""/></Link>
                                <Link to={'/'}><img src="/images/static/logo.svg" alt=""/></Link>
                            </div>
                        </Col>
                        <Col md={{span:4,offset:1}} className={'d-flex justify-content-end'}>
                            <ul>
                                {
                                    window?.innerWidth>767 ?
                                        <li>
                                            <Button text={'16677'} color={'#FFFFFF'} background={'#F16A11'} hoverColor={'#56575A'} hoverBackground={'#FFFFFF'} hoverBorder={'#FFFFFF'} borderColor={'#F16A11'} href={'tel:16677'}/>
                                        </li> :
                                        <li>
                                            <a href={'tel:16677'}><img src="/images/static/phone.svg" alt=""/></a>
                                        </li>

                                }

                                <li className={'hamburger slide'}>
                                    <div>
                                        <span/>
                                        <span/>
                                    </div>
                                    {
                                        window.innerWidth > 767 ?
                                            <h6>MENU</h6> : ''
                                    }
                                </li>
                            </ul>
                        </Col>
                    </Row>

                </div>
            </Container>


            {/*menu item slide */}
            <div className="slide-menu">
                <div className={'bg-noise'}/>

                {
                    window.innerWidth > 767 ?
                        <div className={'close-btn'}>
                            <div className={'d-flex'}>
                                <img src={'/images/static/close.svg'}/>
                                <h6>CLOSE</h6>

                            </div>
                        </div> :
                        <div className={'mobile-top'}>
                            <Link to={'/'}><img src="/images/static/logo.svg" alt=""/></Link>
                            <CloseButton bg={'#151617'} border={'#151617'}/>
                        </div>

                }

                <ul>

                    <Accordion>
                        <li className={location?.pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link></li>
                        <li className={location?.pathname === '/about' ? 'active' : ''}><Link to={'/about'}>About</Link></li>

                        <Accordion.Item
                            className={location?.pathname.startsWith('/projects') ? 'active' : ''}
                            eventKey="1">
                            <Accordion.Header><span>Projects</span> <img src="/images/static/caret-down.svg"
                                                                         alt=""/></Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link to="/projects?status=ongoing"><h5>Ongoing Projects</h5></Link></li>
                                    <li><Link to="/projects?status=completed"><h5>Completed Projects</h5></Link></li>
                                    <li><Link to="/projects?status=upcoming"><h5>Upcoming Projects</h5></Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <li className={location?.pathname === '/landowner' ? 'active' : ''}><Link
                            to={'/landowner'}>Landowner</Link></li>
                        <li className={location?.pathname === '/media-center' ? 'active' : ''}><Link
                            to={'/media-center'}>Media Center</Link></li>
                        <li className={location?.pathname === '/contact' ? 'active' : ''}><Link
                            to={'/contact'}>Contact</Link></li>
                    </Accordion>
                </ul>

            </div>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 68px;
  padding-top: 30px;
  padding-bottom: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  transition: all .6s ${ease};

  .menu-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    inset: 0;
    display: none;
    opacity: 0;
  }
  }

  .main-menu__logo {
    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-of-type(2) {
        display: none;
      }
    }
  }
  .container{
    
  .main-menu__bar {
    @media(max-width: 767px){
      ul{
        li{
          &:first-child{
            width: 45px;
            height: 45px;
          }
        }
      }
    }

    .buttons{
      display: flex;
      align-items: center;
      .dc-btn{
        &:first-child{
          width: 60%;
        }
        &:last-child{
          //margin-left: 30px;
        }
      }
      @media(max-width: 1500px){
        .dc-btn{
          &:first-child{
            width: 80%;
          }
        }
      }
      @media(max-width: 1200px){
        .dc-btn{
          &:first-child{
            width: 120%;
          }
        }
      }
    }
    ul {
      display: flex;
      height: 100%;
      align-items: center;

      .slide {
        position: relative;

        &:after {
          content: '';
          height: 100%;
          left: 0;
          background-color: ${whites};
          position: absolute;
          width: 0;
          transition: width .6s ${ease};
        }
      }

      li {
        height: 100%;
        //width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        //cursor: pointer;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
        }
        svg {
          position: relative;
          z-index: 3;

          path {
            transition: all .6s ease;
          }
        }

        &:nth-of-type(1) {
          // border-left: 1px solid ${whites};
        }

        &:not(:nth-last-child(1)) {
          // border-right: 1px solid ${whites};
        }

        //hover
        &:hover {
          &.slide:after, .slide:after {
            //width: 100%;
          }

          path {
            //stroke: #212158;
            fill: #212158;
          }
        }
        &:first-child{
          @media (max-width: 767px){
            background-color: black;
            padding: 15px;
            border-radius: 50%;
          }
        }

      }

      .hamburger {

        padding-left: 30px;
        display: flex;
        h6{
          color: #FFFFFF;
          margin-left: 10px;
        }
        
    
        span {
          height: 2px;
          width: 40px;
          background-color: ${hover};
          border-radius: 2px;
          display: block;
          position: relative;
          z-index: 2;
          transition: all .6s ${ease};
          margin-bottom: 7px;

          &:last-child {
            margin-bottom:  0;
          }

        }

        &:hover {
          span {
            background-color: ${hover};
          }
        }
        @media(max-width: 767px){
          padding-left: 30px;
          
          div{
            background-color: black;
            padding: 15px;
            border-radius: 50%;
          }
          li{
           
          }

          span{
            width: 15px;
            background-color: white;
            &:last-child {
              margin: 4px 0;
              margin-left: 8px;
              width: 7px;
              display: flex;
              align-items: end;
            }
          }
        }
      }
    }
  }
  }


  //slide menu 
  .slide-menu {
    position: fixed;
    height: 100vh;
    width: 468px;
    right: 0;
    top: 0;
    z-index: 9999;
    padding:  140px 70px;
    //display: flex;
    align-items: center;
    display: none;
    transform: translateX(100%);
    flex-wrap: wrap;
    .bg-noise{
      position: absolute;
      inset: 0px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #56575A;
      z-index: -2;

      &:after{
        content: "";
        background-image: url('/images/static/noise.svg');
        background-blend-mode: multiply;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0px;
        opacity: 1
      }
    }
    
    .close-btn{
      position: absolute;      
      right: 98px;
      top: 42px;
      //cursor: pointer;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      h6{
        color: ${whites};
        margin-left: 10px;
      }

      &:hover {
        .initial-close {
          transform: translateY(-40px);
        }

        span {
          transform: none;
        }
      }

      //@media (max-width: 767px) {
      //  top: 20px;
      //  right: 15px;
      //}
    }
    &__close {
      position: absolute;
      right: 30px;
      top: 30px;
      //cursor: pointer;
      z-index: 2;

      #Ellipse_637, line {
        transition: all .5s ease;
      }

      &:hover {
        line {
          stroke: ${text};
        }

        #Ellipse_637 {
          cy: -20;
        }

        #Group_23147 {
          cy: -10
        }
      }
    }
    .active a, .active span {
      border-bottom: 2px solid ${whites};
      color: #ffffff;
    }
    a {
      font-size: 20px;
      line-height: 28px;
      color: rgba(245, 245, 245, 0.5);
      font-family: ${title};

      &:hover {
        color: rgb(245, 245, 245) !important;
      }
    }
    ul {
      width: 100%;

      li {
        &:not(:nth-last-child(1)) {
          margin-bottom: 20px;
        }

        transform: translateY(20px);
        opacity: 0;

      }
    }
    .accordion-item {
      transform: translateY(20px);
      opacity: 0;

      a {
        border: none
      }
    }

    .accordion-header {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;

      button {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        border: none;
        font-size: 20px;
        line-height: 28px;
        color: rgba(245, 245, 245, 0.6);
        font-family: ${title};
        margin-bottom: 20px;
        transition: color .6s ease;
        width: 100%;
        text-align: left;
        position: relative;
        color: ${whites};

        img {
          position: absolute;
          right: 0;
          top: 11px;
          transform: rotate(180deg);
        }


        &:hover {
          color: rgb(245, 245, 245) !important;
        }

        &.collapsed {
          color: rgba(245, 245, 245, 0.5);

          img {
            transform: rotate(0deg);
          }
        }
      }
    }

    .accordion-body {
      margin-bottom: 20px;

      ul {
        padding-left: 20px;
      }
      h5{
        color: ${whites};

      }

      a {
        font-size: 15px;
        line-height: 20px;
        color: ${whites};

        &:hover {
          color: rgba(245, 245, 245, 0.5) !important;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .goto-buttons {
      display: none;
    }
  }

  @media (max-width: 767px) {
    
    .mobile-top{
      border-bottom: 2px solid red;
      a{
        position: absolute;
        //height: 40px;
        //width: 40px;
        //border-radius: 50%;
        left: 15px;
        top: 30px;
        //cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
      }

    }

    .main-menu__logo {
      img {
        width: 158px;
        height: 35px;
      }
    }

    .main-menu__bar {
      .row{
        justify-content: space-between;
        align-items: center;
        .col-md-4, .col-md-2{
          width: auto;
        }
      }

      ul li {
        width: 55px;

        &:nth-of-type(2), &:nth-of-type(3) {
          display: none;
        }

        &.hamburger {
          padding-left: 19px;
          padding-right: 15px !important;
          width: 60px !important;
          span{
            width: 15px;
          }
        }
      }
    }

    .slide-menu {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      align-items: normal;
      overflow: auto;
      padding-top: 80px;

      ul{
        margin-top: -15px;
        border-top: 1px solid ${whites};
      }

      .goto-buttons {
        height: fit-content;

        img {
          width: 100%;
          display: block;

          &:nth-of-type(1) {
            margin-bottom: 20px;
          }
        }
      }

      .accordion {
        margin-top: 60px;
      }
      .accordion-body{
        ul{
          padding-top: 20px;
        }
      }
    }

  }

  @media (max-width: 500px) {
    .slide-menu {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      align-items: normal;
      overflow: auto;
      padding-top: 100px;

    }
  }


  //caret
  .custom__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(245, 245, 245, 0.5) !important;
    border-radius: 0;
    box-shadow: none;
    outline: none !important;
    //cursor: pointer;
    //margin-bottom: 60px;
    margin-bottom: 20px;


    svg line {
      stroke: ${whites}
    }

    .custom__single-value {
      color: ${whites};
      font-size: 15px;
      line-height: 20px;
    }

    .custom__placeholder {

      color: rgba(245, 245, 245, 0.5);
      font-size: 15px;
      line-height: 20px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {

    }
  }

  .custom__menu {
    z-index: 9;
  }

  .css-t3ipsp-control:hover {
    border-color: ${whites};
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding-right: 0;
  }
`;

export default MyComponent;
