import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {postForm} from "../../api/redux/contacts";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import ButtonV2 from "../ButtonV2";
const MyComponent = ({id,data}) => {

    const dispatch = useDispatch();

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
            e?.preventDefault();

        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         // success(responseData?.success)
    //     }
    //
    // }, [responseData])

    return (
        <StyledComponent id={id} className={'pt-120 pb-120'}>
            <div className={'bg-noise'}/>

            <Container >
                <Row>
                    <Col md={{span:8,offset:2}}>
                        <Title text={data?.title ? data?.title : 'Request a callback'} color={'#EDEEEF'} fontWeight={'900'} center/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:10,offset:1}}>
                        <Form onSubmit={handleSubmit(onSubmit,onError)}>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                type="text" placeholder="Full Name"
                                {...register("name",{
                                    required: 'Username is required',
                                })}
                            />
                            <div className="form__phoneEmail">
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email",{
                                        required:{
                                            value:true,
                                            message:'please enter your email'
                                        },
                                        pattern:{
                                            value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Email*"/>
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone",{
                                        required:{
                                            value:true,
                                            message:'please enter your phone first'
                                        },
                                        pattern:{
                                            value:/^01[0-9]{9}$/,
                                            message:'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number" placeholder="Phone Number*"/>

                            </div>
                            <Form.Control
                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("message",{
                                    required:{
                                        value:true,
                                        message:'please enter your message'
                                    },

                                })}
                                type="textarea" placeholder="Message"/>

                            <div className={'d-flex justify-content-center mobile'}>
                                <div onClick={handleSubmit(onSubmit,onError)}>
                                    <ButtonV2 text={'SUBMIT MESSAGE'}
                                              borderColor={'#FFFFFF'}
                                              hoverBorder={'#F16A11'}
                                              color={'#FFFFFF'}
                                              fontWeight={'700'}
                                              background={'transparent'}
                                              hoverBackground={'#F16A11'}
                                    />

                                    {/*<Button text={'SUBMIT MESSAGE'}*/}
                                    {/*        fontWeight={'700'}*/}
                                    {/*        background={'transparent'}*/}
                                    {/*        hoverBackground={'#F16A11'}*/}
                                    {/*        color={'#FFFFFF'}*/}
                                    {/*        borderColor={'#FFFFFF'}*/}
                                    {/*        hoverBorder={'#F16A11'}*/}

                                    {/*/>*/}
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
 

  .bg-noise{
    position: absolute;
    inset: 0px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #56575A;

    &:after{
      content: "";
      background-image: url('/images/static/noise.svg');
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0px;
      opacity: 1
    }
  }

  .title {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(237, 238, 239, 0.5);
    margin-bottom: 60px;
    color: #FFFDFB;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    font-weight: 500;
    padding-left: 0;
    padding-bottom: 20px;

    &::placeholder {
      color: rgba(237, 238, 239, 0.5);
    }

    &:focus {
      border-bottom: 1px solid #F2F2F2 !important;
    }

  }

  .form__phoneEmail {
    display: flex;
    gap: 60px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0;
      input {
        width: 100% !important;
      }
    }

    input {
      width: 49%;
    }
  }

  input[type="textarea"] {
    padding-bottom: 140px;
    @media(max-width: 767px){
      padding-bottom: 94px;
    }
  }

  .dc-btn {

  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white;

    &:focus {
      border-bottom: 1px solid #F2F2F2 !important;
    }

    &:hover {
      border-bottom: 1px solid #F2F2F2 !important;
    }
  }
  @media(max-width: 767px){
    .mobile{
      justify-content: start !important;
    }

  }

`;

export default MyComponent;
