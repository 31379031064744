import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className="at-a-glance ">
            <Container fluid >
                <Row>
                    <Col md={4} className={'at-a-glance__right'}>
                        {
                            data?.images && data?.images?.length>0 &&
                            <Swiper
                                loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                slidesPerGroup={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={false}
                                speed={900}
                                // infinity={true}
                                pagination={{
                                    clickable: true
                                }}
                                navigation={{
                                    prevEl: '.slider_prev',
                                    nextEl: '.slider_next ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                // autoplay={{
                                //     delay: 4000,
                                //     disableOnInteraction: false,
                                // }}
                                style={{ zIndex: 1 }}
                            >
                                {
                                    data?.images && data?.images?.length>0 &&
                                    data?.images?.map((element,index)=>{
                                        return(
                                            <SwiperSlide key={index}>
                                                <div className="at-a-glance__right__image">
                                                    <Img src={element?.image}/>
                                                </div>
                                            </SwiperSlide>
                                        )
                                })
                                }
                            </Swiper>
                        }

                        <div className="swiper-pagination" style={{ zIndex: 2 }}/>
                    </Col>
                    <Col md={8} className={'at-a-glance__left'}>
                        <Title text={data?.title} margin={'0 0 60px'} color={'#EDEEEF'} />
                        <ul >
                            {
                                data?.list && data?.list?.length>0 &&
                                data?.list?.map((element)=>{
                                    return(
                                        <li>
                                            <div className="icon">
                                                <img src={element?.icon}/>
                                            </div>
                                            <div className="content">
                                                <div className="item"><p>{element?.title}</p></div>
                                                <div className="value"><p>{element?.short_desc}</p></div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #56575A;

  .at-a-glance {
    &__left {
      padding-left: 100px;
      padding-top: 120px;
      padding-bottom: 60px;

      ul {
        li {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 40px;

          &:last-child {
            margin: 0;
          }

          .icon {
            flex: 0 0 50px;

            img {
              margin-top: -4px;
            }
          }

          .content {
            display: flex;
            align-items: center;
            flex: 0 0 calc(100% - 50px);

            .item {
              flex: 0 0 calc(50% - 50px);

              p {
                color: rgba(237, 238, 239, 0.5);
                text-transform: uppercase;
              }
            }

            .value {
              p {
                color: #EDEEEF;
                text-transform: uppercase;
              }
            }
          }

        }
      }

    }
    &__right {
      padding: 0;
      position: relative;
      
      .swiper-pagination{
        position: absolute;
        bottom: 40px;
        width: 100%;
        z-index: 99999;
      }

      &__image {
        padding-top: calc(768 / 498 * 100%);
        min-height: 100%;

      }
    }
  }


  @media (max-width: 767px) {
    .at-a-glance {
      &__left {
        padding-left: 30px;
        padding-top: 80px;
        margin-top: 0px;

        h2 {
          margin-left: 0;
        }

        ul {
          li {
            &:last-child {
              margin: 0;
            }

            .content {
              flex-direction: column;
              align-items: start;
            }
          }
        }

      }

      &__right {
        padding: 0;

        &__image {
          position: relative;
          padding-top: calc(620 / 470 * 100%);
          min-height: 100%;
        }
      }
    }
  }
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
    opacity: 1;
    height: 5px;
    padding: 0;
    border-radius: 2px;
    width: 20px;
    margin-right: 20px !important;
    @media (max-width: 600px) {
      width: 15px;
      margin-right: 10px !important;
    }

    &.swiper-pagination-bullet-active {
      width: 60px;
      opacity: 1;
      background-color: ${hover};
      @media (max-width: 600px) {
        width: 25px;
      }
    }
  }

`;


export default MyComponent;
