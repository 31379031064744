import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {grayText, white} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {




    return (
        <StyledComponent className={'about'} >
            <Container>
                <div className={'bg-noise'}/>
                <Row>
                    <Col md={{span:5,offset:1}}>
                        <div className={'about__vision first'}>
                            <h4 className={'split-up'}>{data?.mtitle}</h4>
                            <h5 className={'split-up'}>{data?.mdescription}</h5>
                        </div>
                    </Col>
                    <Col md={{span:5}}>
                        <div className={'about__vision'}>
                            <h4 className={'split-up'}>{data?.vtitle}</h4>
                            <h5 className={'split-up'}>{data?.vdescription}</h5>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  background: #EDEEEF;
  z-index: -2;
  .col-md-5{
    &:first-child{
      padding-left: 0px !important;
      padding-right: 0px !important; 
    }    
  }
  
  
  //.row{
  //  margin-left: -30px !important;
  //}
  &:after {
    height: 50%;
    position: absolute;
    bottom: 0;
    //top: 0;
    left: 0;
    right: 0;
    background: ${grayText};
    content: "";
    // display: ${props => props.remove ? 'none' : 'block'};
    // opacity: ${props => props.remove ? '0' : '1'};
    z-index: -1;
  }

  
  .container{
    position: relative;
    .bg-noise{
      position: absolute;
      inset: 0px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #56575A;

      &:after{
        content: "";
        background-image: url('/images/static/noise.svg');
        background-blend-mode: multiply;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0px;
        opacity: 1
      }
    }
  }


  h4{
      margin-bottom: 40px;
      color: ${white};

    }
  h5{
    color: ${white};
  }
  .about{
    &__vision{
      padding-top: 120px;
      padding-bottom: 120px;
      &.first{
        margin-right: 20px;
      }
    }
  }
  @media(max-width: 767px) {
    .about{
      &__vision{
        padding-top: 70px;
        padding-bottom: 70px;
        .first{
          margin-right: 0px;
        }
      }
    }
  }
}
`
export default MyComponent;