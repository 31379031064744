import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import {medium} from "../../styles/globalStyleVars";

const Overview = ({img, icon, title, subtitle,src,size}) => {
    return (
        <StyledOverview className='project-box'>
            <Link to={src}>
                <div className="project-box__single">
                    <div className="project-box__single__image ">
                        <Img src={img? img : '/images/dynamic/project/project-1.jpg'} />
                    </div>
                    <div className="project-box__single__text">
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <h6 className={'split-up'}>{subtitle}</h6>
                        </div>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <p >{title ? title : 'Magnolia'}</p>
                        </div>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <h6 >{size? size : ''}</h6>
                        </div>
                    </div>
                    <div className="project-box__single__icon">
                        <img src={icon? icon : ''}/>
                    </div>
                </div>
            </Link>
        </StyledOverview>
    );
};

const StyledOverview = styled.div`
  overflow: hidden;
  position: relative;

  .project-box__single {

    position: relative;
    margin-bottom: 30px;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      //cursor: pointer;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0); /* Transparent background initially */
      opacity: 0; /* Initially hidden */
      transition: background 0.3s ease, opacity 0.3s ease; /* Transitions for the tint effect */
      z-index: 11111;
    }


    &:hover {
      .global-image {
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.06);
      }

      /* Show the tint on hover */

      &::before {
        background: rgba(0, 0, 0, 0.4); /* Adjust the color and opacity as needed */
        opacity: 1;
      }

      .project-box__single__icon img {
        opacity: 1;
      }
    }

    &__image {
      position: relative;
      padding-top: calc(500 / 370 * 100%);
      overflow: hidden;

      .global-image {
        overflow: hidden;
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
      }


    }

    &__text {
      position: absolute;
      bottom: 20px;
      margin: auto;
      left: 0;
      right: 0;
      //transform: translate(-50%,-50%);
      z-index: 11111;
      
      p {
        font-size: 28px;
        line-height: 32px;
        color: #EDEEEF;
        text-transform: uppercase;
      }

      h6 {
        color: #EDEEEF;
        text-transform: uppercase;
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //cursor: pointer;
      z-index: 999999;

      img {
        opacity: 0;
        transition: opacity 0.7s ease;

      }
    }
  }
  @media(max-width: 767px){
    p{
      font-size: 24px;
      line-height: 32px;
    }
  }


`;

export default Overview;
