import React, {useEffect} from 'react';
import styled from "styled-components";

import {
    useParams,
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/project";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import Overview from "../../components/Overview";
import InnerBanner from "../../components/project/InnerBanner";
import Video from "../../components/Video";
import Gallery from "../../components/project/Gallery";
import Map from "../../components/Map";
import ProjectForm from "../../components/project/ProjectForm";
import RelatedProjects from "../../components/project/RelatedProjects";
import FeatureSlider from "../../components/project/FeatureSlider";
import Glance from "../../components/project/Glance";
import StickyMobileNav from "../../components/landowner/StickyMobileNav";

const MyComponent = () => {

    const dispatch = useDispatch()
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECTDETAIL
        dispatch(fetchPostDetail([api_url + `/${slug}`]))
    }, [])

    let getPost = useSelector(state => state.project)


    // data refactor

    let banner=getPost?.detail?.page_data?.[0]?.banner;
    let overview=getPost?.detail?.page_data?.[0]?.overview;
    let glance=getPost?.detail?.page_data?.[0]?.at_a_glance;
    let feature=getPost?.detail?.page_data?.[0]?.feature;
    let video=getPost?.detail?.page_data?.[0]?.video;
    let gallery=getPost?.detail?.page_data?.[0]?.gallery;
    let map=getPost?.detail?.page_data?.[0]?.map;
    let form=getPost?.detail?.page_data?.[0]?.form;
    let related_project=getPost?.detail?.projects;

    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Project Detail | Rancon Real Estate Ltd.</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={getPost?.detail?.[0]?.post_title} subtext={'News & Events'}
                    img={banner?.image}
                    mimg={banner?.mimage}
                    location={banner?.project_location}
                    type={banner?.project_type}
                    size={banner?.size}
                    icon={banner?.icon}
                />
                {
                    window?.innerWidth<767 &&
                    <StickyMobileNav/>
                }
                {
                    overview?.description &&
                    <Overview desc={overview?.description} hasbtn bg={overview?.bg_texture} pdf={overview?.brochure_file}/>
                }
                <Glance data={glance}/>
                {
                    feature?.list?.length>0 &&
                    <FeatureSlider data={feature}/>
                }
                {
                    video?.image &&
                    <Video fromDetail data={video}/>
                }
                <Gallery data={gallery}/>
                {
                    map?.image &&
                    <Map data={map}/>
                }
                <ProjectForm id={'request-callback'} data={form}/>
                <RelatedProjects data={related_project}/>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
