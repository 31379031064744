import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import {ImageParallax} from "./ImageParallax";
import {white} from "../styles/globalStyleVars";


const InnerBanner = ({img, subtext, title, uptitle,project,notFull,parallax,mimg}) => {
    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner parallax'>
            {
                window?.innerWidth>767 ?
                    <ImageParallax parallax banner={true} src={img ? img : ''}/> :
                    <ImageParallax banner={true} src={mimg ? mimg : img}/>
            }

            <Container>
                <Row>
                    <Col md={{span:8}}>
                        <h1 >{title ? reactHtmlParser(title) : ''}</h1>
                        <h6 >{subtext ? reactHtmlParser(subtext) : ''}</h6>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '75vh' : '100vh'} ;
  position: relative;
  //background-color: #DDD;
  height:${props => props.notFull ? '75vh' : '100vh'};
  overflow: hidden;

  &:after {
    //content: '';
    position: absolute;
    //background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;

    h1 {
      margin-bottom: 20px ;
    }
    h6{
      color: ${white};
      text-transform: uppercase;
    }

    h1, p {
      color: #F1EEE9;
      text-transform: uppercase;
      font-weight: 900;

    }

    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media(max-width: 767px){
    //padding-top: 0;
    height: auto;
    padding-top: 75vh ;

    .container {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      text-align: left;
      margin-bottom: 60px;

      h1 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  }


  //@media (max-width: 376px) {
  //  padding-top: calc(812 / 375 * 100%);
  //  h1{
  //    font-size: 36px;
  //    line-height: 48px;
  //  }
  //}




`;

export default InnerBanner;
