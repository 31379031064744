import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import CloseButton from "./CloseButton";
import SocialIcon from "./SocialIcon";
import ReactHtmlParser from "react-html-parser";
const PopupV1 =({
                    show,
                    handleClose,
                    no_img,
                    item,
                    title,
                    description,
                    data,
                    subtitle,
                    img
                }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one popup"
            >
                <SimpleBar className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="close-area ">
                                    <CloseButton/>
                                </div>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <Col sm={4} className="modal-data__img">
                                        <img src={data?.image}/>
                                        <h5>{data?.title}</h5>
                                        <p>{data?.designation}</p>
                                        <ul >
                                            {
                                                data?.facebook &&
                                                <li>
                                                    <SocialIcon img={'/images/static/fb.svg'} link={data?.facebook}/>
                                                </li>
                                            }
                                            {
                                                data?.profile &&
                                                <li>
                                                    <SocialIcon img={'/images/static/twitter.svg'} link={data?.profile}/>
                                                </li>
                                            }
                                            {
                                                data?.instagram &&
                                                <li>
                                                    <SocialIcon img={'/images/static/insta.svg'} link={data?.instagram}/>
                                                </li>
                                            }
                                            {
                                                data?.linkedin &&
                                                <li>
                                                    <SocialIcon img={'/images/static/linkedin.svg'} link={data?.linkedin}/>
                                                </li>
                                            }
                                            {
                                                data?.youtube &&
                                                <li>
                                                    <SocialIcon img={'/images/static/youtube.svg'} link={data?.youtube}/>
                                                </li>
                                            }

                                        </ul>
                                    </Col>

                                    <Col sm={{span: 7, offset: 1}} className='modal-data__content'>
                                        <div className="scroll-div">
                                            <Col md={12}>
                                                {window.innerWidth > 767 ?
                                                    <SimpleBar autoHide={true} style={{maxHeight: 'calc(100vh - 100px)'}}>
                                                        {ReactHtmlParser(data?.description)}
                                                    </SimpleBar>
                                                    :
                                                    <>
                                                        {ReactHtmlParser(data?.description)}
                                                    </>
                                                }
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }
 
  h5{
    color: #EDEEEF !important;
  }

  .footer__social {
    .col-sm-4 {
      min-width: 100%;
      justify-content: flex-start !important;

      &:nth-of-type(1) {
        display: none;
      }
      &:nth-last-of-type(1) {
        //display: none !important;
      }
    }
  }
  
`;


export default React.memo(PopupV1);
