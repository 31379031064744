import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Button from "../Button";
import Title from "../Title";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {medium, text} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import Select, {components} from "react-select";
import ButtonV2 from "../ButtonV2";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/landowner";
const MyComponent = ({data}) => {

    const dispatch = useDispatch();

    const {register, handleSubmit ,formState,reset,control} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.LANDOWNER_FORM;

        var formData = new FormData();
        formData.append('location', e?.location);
        formData.append('your-address', e?.address);
        formData.append('your-landsize', e?.landsize);
        formData.append('landowner-name', e?.landowner);
        formData.append('contact-person-name', e?.person);
        formData.append('contact-number', e?.phone);
        formData.append('your-email', e?.email);
        formData.append('facing', e?.facing?.label);

        formData.append('form_id', 'landowner-form');

        if (e.email !== '' && e.location !== '' && e.phone !== '' &&  e?.address !=='' && e?.landowner!=='' && e?.person && e?.phone!=='' ) {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };


    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         // success(responseData?.success)
    //     }
    //
    // }, [responseData])

    const option = [
        {value: 'option1', label: 'Select 1'},
        {value: 'option2', label: 'Select 2'},
    ]

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            // cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                // cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 12,
        }),

    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
      ;
    };

    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <div className={'bg-noise'}/>

            <Container >
                <Row>
                    <Col md={{span:8,offset:2}}>
                        <Title text={data?.title} color={'#EDEEEF'} fontWeight={'900'} center/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:8,offset:2}}>
                        <Form onSubmit={handleSubmit(onSubmit,onError)}>
                            <Row>
                                <Col md={6}>
                                    <h5>Land Information</h5>
                                    <Form.Control
                                        className={formState?.errors?.location?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="text" placeholder="Location*"
                                        {...register("location",{
                                            required: 'Username is required',
                                        })}
                                    />
                                    <Form.Control
                                        className={formState?.errors?.address?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="text" placeholder="Address*"
                                        {...register("address",{
                                            required: 'Username is required',
                                        })}
                                    />
                                    <Form.Control
                                        className={formState?.errors?.landsize?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="text" placeholder="Size of the Land*"
                                        {...register("landsize",{
                                            required: 'Username is required',
                                        })}
                                    />
                                    <Controller
                                        control={control}
                                        name="facing"
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isSearchable={false}
                                                classNamePrefix="filter"
                                                options={option}
                                                placeholder="facing"
                                                styles={customStyles}

                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={6} className={'profile'}>
                                    <h5>Landowner's Profile</h5>
                                    <Form.Control
                                        className={formState?.errors?.landowner?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="text" placeholder="Name of the Landowner*"
                                        {...register("landowner",{
                                            required: 'Username is required',
                                        })}
                                    />
                                    <Form.Control
                                        className={formState?.errors?.person?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="text" placeholder="Contact Person"
                                        {...register("person",{
                                            required: 'Username is required',
                                        })}
                                    />
                                    <Form.Control
                                        className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="email" placeholder="Email"
                                        {...register("email",{
                                            required:{
                                                value:true,
                                                message:'please enter your email'
                                            },
                                            pattern:{
                                                value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:'please enter a valid email address'
                                            }
                                        })}
                                    />
                                    <Form.Control
                                        className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        type="number" placeholder="Phone*"
                                        {...register("phone",{
                                            required:{
                                                value:true,
                                                message:'please enter your phone first'
                                            },
                                            pattern:{
                                                value:/^01[0-9]{9}$/,
                                                message:'please enter a valid 11 digit phone number'
                                            }
                                        })}
                                    />
                                </Col>
                            </Row>
                            <div className={'d-flex justify-content-center mobile'} >
                                <div onClick={handleSubmit(onSubmit,onError)}>
                                    <ButtonV2 text={'SUBMIT MESSAGE'}
                                              borderColor={'#FFFFFF'}
                                              hoverBorder={'#F16A11'}
                                              color={'#FFFFFF'}
                                              fontWeight={'700'}
                                              background={'transparent'}
                                              hoverBackground={'#F16A11'}
                                    />
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: ${text};
position: relative;
  overflow: hidden;
  .bg-noise{
    position: absolute;
    inset: 0px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #56575A;

    &:after{
      content: "";
      background-image: url('/images/static/noise.svg');
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0px;
      opacity: 1
    }
  }

  h5 {
    color: #EDEEEF;
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .filter {
    &__indicator {
      margin-right: 0px;
    }

    &__placeholder {
      color: rgba(237, 238, 239, 0.5) !important;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.2px;
      font-weight: 500;
    }
  }

  .css-1u9des2-indicatorSeparator {
    background-color: transparent !important;
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(237, 238, 239, 0.5);
    margin-bottom: 60px;
    color: #FFFDFB;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    font-weight: 500;
    padding-left: 0;
    padding-bottom: 20px;

    &::placeholder {
      color: rgba(237, 238, 239, 0.5);
    }

    &:focus {
      border-bottom: 1px solid #F2F2F2 !important;
    }

    &:hover {
      border-color: rgba(237, 238, 239) !important;
    }

  }

  .css-t3ipsp-control {
    box-shadow: 0px 0px transparent;
    border-color: transparent !important;
    border-bottom: 1px solid rgba(237, 238, 239, 0.5) !important;
    border-radius: 0 !important;

    &:hover {
      border-color: transparent !important;
    }
  }

  .form__phoneEmail {
    display: flex;
    gap: 60px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0;
      input {
        width: 100% !important;
      }
    }

    input {
      width: 49%;
    }
  }

  input[type="textarea"] {
    padding-bottom: 140px;
    @media (max-width: 767px) {
      padding-bottom: 94px;
    }
  }

  .dc-btn {

  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white;

    &:focus {
      border-bottom: 1px solid #F2F2F2 !important;
    }

    &:hover {
      border-bottom: 1px solid #F2F2F2 !important;
    }
  }

  @media (max-width: 767px) {
    .mobile {
      justify-content: start !important;
    }
    .profile{
      margin-top: 60px;
    }

  }

  .css-13cymwt-control {
    border-color: rgba(237, 238, 239, 0.5) !important;
    border-radius: 0px !important;
  }
  .css-1fdsijx-ValueContainer{
    padding: 0 ;
  }

  .css-1dimb5e-singleValue{
    font-size: 12px;
    line-height: 16px;
  }
`;

export default MyComponent;
