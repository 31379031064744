import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchLandowner, fetchPosts} from "../../api/redux/landowner";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import LandownerForm from "../../components/landowner/LandownerForm";


const MyComponent = () => {


    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.LANDOWNER
        dispath(fetchLandowner([api_url]))
    }, [])

    let getPost = useSelector(state => state.landowner)


    // data refactor

    let banner=getPost?.posts?.[0]?.banner;
    let overview=getPost?.posts?.[0]?.overview;
    let form=getPost?.posts?.[0]?.form;
    let meta=getPost?.posts?.[0]?.meta;



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Landowner | Rancon Real Estate Ltd.</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>
                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>
                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>
                }            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.title} subtext={banner?.subtitle}
                             img={banner?.image} mimg={banner?.mobile_image}/>
                <Overview desc={overview?.description}/>
                <LandownerForm data={form}/>
            </StyledComponent>
        </HelmetProvider>
    );
};
const StyledComponent = styled.section`
`
export default MyComponent;