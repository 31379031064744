import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import {Col, Container, Row} from "react-bootstrap";

import {heavy, hover, medium, text, title, white} from "../../styles/globalStyleVars";
import SelectField from "./SelectField";
import GoogleMapsMarkers from "./GoogleMapsMarkers";


const GoogleMap = ({offset, revers, padding, data}) => {


    const type = data && data?.map(item1 => ({
        value: item1?.project_type,
        label: item1?.project_type,
    }));

    const unique_type = Array.from(new Set(type?.map((option) => option.value)))?.map((value) => type.find((option) => option.value === value));

    const status = data && data?.map(item1 => ({
        value: item1?.project_status,
        label: item1?.project_status,
    }));

    const unique_status = Array.from(new Set(status?.map((option) => option.value)))?.map((value) => status.find((option) => option.value === value));


    const location = data && data?.map(item1 => ({
        value: item1?.project_location,
        label: item1?.project_location,
    }));

    const unique_location = Array.from(new Set(location?.map((option) => option.value)))?.map((value) => location.find((option) => option.value === value));


    // Define state variables for selected filters
    const [selectedType, setSelectedType] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [filteredData, setFilteredData] = useState(data);


    useEffect(() => {
        // Filter the data based on selected filters
        const newFilteredData = data && data.filter(item => {
            const typeMatch = !selectedType || item.project_type === selectedType.value;
            const statusMatch = !selectedStatus || item.project_status === selectedStatus.value;
            const locationMatch = !selectedLocation || item.project_location === selectedLocation.value;

            return typeMatch && statusMatch && locationMatch;
        });

        setFilteredData(newFilteredData);
    }, [selectedType, selectedStatus, selectedLocation, data]);


    // Callback function to handle selected type
    const handleTypeSelect = (selectedValue) => {
        setSelectedType(selectedValue);
    };

// Callback function to handle selected status
    const handleStatusSelect = (selectedValue) => {
        setSelectedStatus(selectedValue);
    };

// Callback function to handle selected location
    const handleLocationSelect = (selectedValue) => {
        setSelectedLocation(selectedValue);
    };



    return (
        <>
            <StyledGoogleMap offset={offset} className={`BuilHome ${padding ? padding : 'pt-200'}`}>
                <div className="noise"></div>
                <Container className={'margin-bottom-fiv'}>
                    <Row>
                        <Col md={12} className={'text-center title'}>
                            <h3 className={'split-up'}>{'Find your home'}</h3>
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Type'}
                                selection={unique_type}
                                onSelect={handleTypeSelect}
                            />
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Status'}
                                selection={unique_status}
                                onSelect={handleStatusSelect}
                            />
                        </Col>
                        <Col md={4}>
                            <SelectField
                                placeholder={'Location'}
                                selection={unique_location}
                                onSelect={handleLocationSelect}
                            />
                        </Col>
                    </Row>
                </Container>

            </StyledGoogleMap>
            <StyledMap className="map">
                <GoogleMapsMarkers data={filteredData}/>
            </StyledMap>
        </>
    )
};

const StyledGoogleMap = styled.div`
  position: relative;
  //background: #26201E;
  background: #F5F5F5;

  .margin-bottom-fiv {
    padding-bottom: 40px;
  }
  .title {
    padding-left: 0;

    h3 {
      text-transform: uppercase;
      font-size: 48px;
      line-height: 56px;
      font-weight: 900;
      margin-bottom: 60px;
      span {
        color: ${hover};
      }
    }
  }

  .noise {
    position: absolute;
    inset: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      background-image: url('/images/static/background-noise.png');
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.2;
    }


  }

  .map {
    height: 100vh;
  }

  h2 {
    color: #08082b;
    font-size: 44px;
    line-height: 52px;
    font-family: ${title};
    text-transform: uppercase;
    margin-bottom: 70px;
  }

  .filter__control .filter__single-value {
    color: #08082B;

  }
  .filter__indicator{
    margin-right: 10px!important;
  }
  .filter__control.filter__control--is-focused {
    border-bottom-color: #08082B !important;

    .filter__placeholder {
      color: #08082B;
    }

    .filter__single-value {
      color: #08082B
    }
  }

  .filter__control {
    border-bottom-color: #08082B !important;

    .filter__placeholder {
      color: #08082B;
    }
  }

  .filter__indicator svg line {
    stroke: #08082B !important;
  }
  @media(max-width: 767px){
    h3{
      font-size: 32px;
      line-height: 40px;
      font-family: ${heavy};
      margin-bottom: 40px;
    }
  }

`;
const StyledMap = styled.div`

  height: 100vh;

  .gm-style .gm-style-iw-c {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate3d(-50%, -100%, 0);
    background-color: ${text};
    padding: 12px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .3);
    min-width: 260px !important;
    min-height: 175px;
    padding: 40px 20px 20px;

    ul {
      display: flex;

      li {
        font-size: 12px;
        font-weight: 500;
        color: #F1EEE9;
        text-transform: uppercase;
        position: relative;
        padding-left: 20px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          height: 5px;
          width: 5px;
          background: #F1EEE9;
          position: absolute;
          right: -12px;
          top: 5px;
          border-radius: 50%;

        }

      }
    }

    p {
      font-size: 24px;
      font-weight: 500;
      color: ${white};
      width: 85%;
      line-height: 32px;
      text-transform: uppercase;
      // font-family: ${medium};
    }

    .map-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      transition: 0.7s all ease;

      svg {
        transition: 0.7s all ease;
      }

      &:hover {
        //transform: translateX(20);

      }
    }
  }

  .gm-ui-hover-effect > span {
    background-color: #F9F5F2;
  }

  .gm-style .gm-style-iw-tc::after {
    background-color: ${text};

  }

  .gm-style .gm-style-iw-d {
    background-color: ${text};

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media (max-width: 767px) {
    height: 70vh;
  }
`

export default React.memo(GoogleMap);
