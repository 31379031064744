import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchHome, fetchPosts} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import HomeOverview from "../../components/home/HomeOverview";
import Video from "../../components/Video";
import WhyUs from "../../components/home/WhyUs";
import FeatureSlider from "../../components/home/FeatureSlider";
import HomeBanner from "../../components/home/HomeBanner";
import ContactForm from "../../components/contact/ContactForm";
import {useLocation} from "react-router-dom";
import GoogleMap from "../../components/home/GoogleMap";
const Home = () => {

    const location = useLocation();
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHome([api_url]))
    }, [])

    let getPost = useSelector(state => state.home)

    useEffect(() => {
        window.scroll(0, 0)
    }, [location?.pathname,getPost])


    // data refactor

    let banner=getPost?.posts?.page_data?.banner?.list;
    let about=getPost?.posts?.page_data?.about;
    let form=getPost?.posts?.page_data?.form;
    let why_us=getPost?.posts?.page_data?.why_us;
    let video=getPost?.posts?.page_data?.video;
    let project_list=getPost?.posts?.projects?.filter(element => element?.isFeatured === 'on');;
    let meta=getPost?.posts?.page_data?.meta;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Rancon Real Estate Ltd.</title>
                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>
                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>
                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>
                }
            </Helmet>
            <StyledSection>
                <HomeBanner data={banner}/>
                {/*<TestingBanner data={banner}/>*/}
                <FeatureSlider data={project_list}/>
                <HomeOverview desc={about?.description} title={about?.subtitle} hasbtn bg={'/images/dynamic/home/bg.jpg'}/>
                <WhyUs data={why_us}/>
                <GoogleMap data={project_list}/>
                <Video data={video}/>
                <ContactForm data={form}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
