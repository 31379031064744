import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import FontTest from "../components/FontTest";
import Button from "../components/Button";
import {hover, text} from "../styles/globalStyleVars";
import InnerBanner from "../components/InnerBanner";
import Fonts from "../components/Fonts";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Font Testing</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent >
                {/*//Type - 01 only text*/}
                {/*<InnerBanner*/}
                {/*    full*/}
                {/*    title={'Taka Talks Insights'}*/}
                {/*    subtitle={'Your Go-to Place for Industry Insights'}*/}
                {/*    text={'Discover curated insider news perfect for helping you in your financial endeavors'}*/}
                {/*    src={'/images/dynamic/takatalks/inner-banner.jpg'}/>*/}


                {/*//Type - 02 with right button*/}
                {/*<InnerBanner*/}
                {/*    right_btn*/}
                {/*    btn_text1={'Invest Now'}*/}
                {/*    btn_text2={'Call Today!'}*/}
                {/*    full*/}
                {/*    subtitle={'Your Go-to Place for Industry Insights'}*/}
                {/*    text={'Discover curated insider news perfect for helping you in your financial endeavors'}*/}
                {/*    src={'/images/dynamic/takatalks/inner-banner.jpg'}/>*/}

                {/*//Type - 03 with button on the bottom*/}
                {/*<InnerBanner*/}
                {/*    full*/}
                {/*    button_banner*/}
                {/*    subtitle={'Your Go-to Place for Industry Insights'}*/}
                {/*    text={'Discover curated insider news perfect for helping you in your financial endeavors'}*/}
                {/*    src={'/images/dynamic/takatalks/inner-banner.jpg'}/>*/}

                {/*<Button src={'/about'} text={'Learn More'} color={'#323232'} background={'#F5F5F5'}/>*/}
                {/*<Button src={'/about'} text={'Learn More'} color={'#FFFFFF'} background={`${hover}`} hoverBackground={`${text}`}/>*/}

                {/*<FontTest/>*/}
                <Fonts/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
