
//----------------------------------------------    Color Variables   --------------------------------------------------------------//


export const text = '#56575A';
export const hover = '#F16A11';
export const white = '#FFFFFF';
export const grayText = '#D7D8DA';


//----------------------------------------------    Font Variables   --------------------------------------------------------------//

export const title = `"Avenir LT Pro", Euclid Square, Arial, Helvetica, freesans, sans-serif `;
export const book = `"Avenir LT Pro 45 Book"`;
export const black = `"Avenir LT Pro 65 black"`;
export const heavy = `"Avenir LT Pro Heavy"`;
export const medium = `"Avenir LT Pro 65"`;
export const light = `"Avenir LT Pro 35 light"`;



//
export const whites = '#F5F5F5';
export const ease = 'cubic-bezier(0.4, 0, 0, 1)';
export const body = `Banana Grotesk, Arial, Helvetica, freesans, sans-serif`

