import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";

import Button from "../Button";
import React, {useEffect, useRef, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import {heavy, hover} from "../../styles/globalStyleVars";
import SingleProject from "../project/SingleProject";
import SliderPrevWhite from "../SliderPrevWhite";
import SliderNextWhite from "../SliderNextWhite";
import ButtonV2 from "../ButtonV2";

const MediaCenter = ({data}) => {


    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)


    useEffect(() => {
        // setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            // setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [null])


    useEffect(() => {
        const getTotalItem = 5
        setTotalNumber(getTotalItem)
    }, [data])

    let sliderNumber = () => {
        const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
        // const getTotalItem = document.querySelector('.swiper-pagination-total').innerHTML
        setActiveNumber(getActiveItem)
        // setTotalNumber(getTotalItem)
    }


    return (
        <StyledBlog className='blog-slider pt-200 pb-200'>
            <Container >
                <Row className="full-title">
                    <Col md={9} className="blog-title">
                        <h3 className={'split-up'}>{'Featured Projects'}</h3>
                    </Col>
                    <Col md={3} className={'d-flex justify-content-end align-items-center mobile'}>
                        <div className={'d-flex justify-content-between align-items-center desktop'}>
                            <div className="blog-button">
                                <div className="slider-nav">
                                    <ul>
                                        <li className=" slider_prev">
                                            <SliderPrevWhite color={'#FFFFFF'}
                                                             background={'#3C3C3B'}
                                                             hoverBackground={'#F16A11'}
                                                             hoverColor={'#FFFFFF'}
                                                             hoverBorder={'#F16A11'}

                                            />

                                        </li>
                                        <li className=" slider_next">
                                            <SliderNextWhite color={'#FFFFFF'}
                                                             background={'#3C3C3B'}
                                                             hoverBackground={'#F16A11'}
                                                             hoverColor={'#FFFFFF'}
                                                             hoverBorder={'#F16A11'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <ButtonV2 src={'/media-center'} text={'VIEW ALL'} margin={'0px 0px 0px 0'} borderColor={'#F16A11'} color={'#56575A'}/>
                                {/*<Button src={'/media-center'} text={'VIEW ALL'} color={''} borderColor={'#F16A11'} hoverBackground={'#F16A11'}/>*/}
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
            <Container>
                <VisibilitySensor offset={{top: -150}}>
                    <div className={` fade-up blog-slider__slider-wrap`}>
                        <div className="blog-slider__slider-wrap__inner">
                            {
                                data && data.length>0 &&
                                <Swiper loop={true}
                                        spaceBetween={30}
                                        slidesPerView={3}
                                        allowSlideNext={true}
                                        allowSlidePrev={true}
                                        allowTouchMove={true}
                                        speed={900}
                                        autoplay
                                    // pagination={{
                                    //     type: "fraction",
                                    // }}
                                        navigation={{
                                            prevEl: '.slider_prev',
                                            nextEl: '.slider_next',
                                        }}
                                        modules={[Autoplay, Pagination, Navigation]}

                                    // autoplay={{
                                    //     delay: 2500,
                                    //     disableOnInteraction: false,
                                    // }}

                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                        }}

                                    // navigation={true} modules={[Navigation]}
                                    // onSwiper={(swiper) => console.log(swiper)}

                                        ref={sliderRef}>

                                    {
                                        data && data.length>0 &&
                                        data.map((element)=>{
                                            return(
                                                <SwiperSlide key={element?.ID} >
                                                    <SingleProject
                                                        src={`/project/${element?.post_name}`}
                                                        img={element?.project_thumb}
                                                        title={element?.post_title}
                                                        subtitle={element?.project_location}
                                                        icon={element?.icon}
                                                        size={element?.size}
                                                    />
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>
                            }
                        </div>
                    </div>
                </VisibilitySensor>
            </Container>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  background-color: #EDEEEF;

  .dc-btn{
    min-width: max-content !important;
  }
  .sub-title {
    margin: 0 0 20px 0;
    font-weight: 500;
  }

  .full-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 80px 0;
    
    
    .col-md-3{
      padding-left: 40px !important;
      padding-right: 0px !important;
    }
    
  }

  .blog-title {
    padding-left: 0;

    h3 {
      text-transform: uppercase;
      font-size: 48px;
      line-height: 56px;
      font-weight: 900;
      span {
        color: ${hover};
      }
    }
  }

  .blog-single {
    .blog-single__inner {
      padding-top: calc(440 / 370 * 100%);
      position: relative;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &:after {
          content: '';
          height: 0;
          width: 100%;
          background-color: ${hover};
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          transition: height .4s ease;
        }

        &__top {
          .upper {
            position: absolute;
            left: 30px;
            top: 40px;
            z-index: 2;
            right: 30px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
          }

          p {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0 0 40px 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .read-more {
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
            color: #FFFFFF;
            margin: 0;
            text-decoration: underline;
          }

          h2 {
            position: absolute;
            top: 40px;
            left: 30px;
            right: 30px;
            z-index: 2;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            color: #FFFFFF;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          left: 30px;
          right: 30px;
          bottom: 24px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h3 {
            color: #FFFFFF;
            transition: color .3s ease;

            p {
              display: block;
              color: #FFFFFF;
            }
          }

          p {
            font-size: 16px;
            color: #FFFFFF;
            text-align: right;
            line-height: 20px;
            transition: color .3s ease;

            span {
              display: block;
              color: #FFFFFF;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content__top {
          h2 {
            opacity: 0;
            transform: translateY(-20px);
          }

          .upper {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h4 {
            color: #ffffff;
          }

          p {
            color: #ffffff;

            span {
              color: #ffffff;
            }
          }
        }
      }

    }

    &:hover {
      .blog-single__inner__content:after {
        height: 100%;
      }
    }
  }


  .blog-button {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 85px;
    align-items: center;
    margin-right: 60px;

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        //height: 50px;
        //width: 50px;
        //background-color: #56575A;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        //cursor: pointer;

        &:nth-of-type(1) {
          margin-right: -10px;
        }

      }
    }
  }

  svg {
    border-radius: 50px;
    #Ellipse_4378 {
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      r: 26;
      //cursor: pointer;
    }

    line {
      stroke: white;
    }

    &:hover {
      #Ellipse_4378 {
        fill: #F16A11;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        cx: 26;
      }

      g {
        line {
          stroke: #f9f9f9;
        }
      }
    }
  }

  .swiper-button-disabled{
    opacity: unset !important;
  }
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color:#F16A11;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    margin-left: ${props => props.offset + 15}px;
    padding-right: ${props => props.offset + 15}px;
  }

  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 115%;
          }
        }
      }
    }
  }

  .slider-nav-mobile {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    .mobile{
      display: flex;
      justify-content: start !important;
    }
    .desktop {
      width: 100%;
    }
    
    .swiper-initialized {
      margin: 0;
      padding: 0;
    }

    .blog-slider {
      &__slider-wrap {
        margin: 0;
      }
    }

    .full-title {
      display: unset;
      margin: 0;
      .mobile{
        margin-bottom: 40px;
      }
      .col-md-3{
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

    .blog-title {
      padding: 0;
      //margin-bottom: 40px;
      h3{
        font-size: 32px;
        line-height: 40px;
        font-family: ${heavy};
      }

    }

    .blog-button {
      //margin-bottom: 40px;
    }

    .blog-single {
      .blog-single__inner {
        padding-top: calc(440 / 375 * 100%);

        &__content {
          &__bottom {
            h3 {
              color: ${hover};
              p {
                color: #FFFFFF;
              }
            }
          }
        }

        &:hover {
          .blog-single__inner__content__bottom {
            h3 {
              color: #ffffff;
            }
          }
        }
      }

      .blog-single__inner__content__top {
        .upper, h2 {
          left: 15px;
          right: 15px;
        }
      }

      .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
        left: 15px;
        right: 15px;
      }
    }

    .slider-nav-mobile {
      margin-top: 40px;
    }

  }

`;
export default MediaCenter;