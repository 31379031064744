import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ease, text} from "../styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";

gsap.registerPlugin(CSSPlugin);

const MyComponent = () => {

    useEffect(() => {
        let tl = gsap.timeline()
        // tl.to('')
        const getLi = document.querySelectorAll('.floating-icon ul li:not(:nth-last-child(1))');
        document.querySelector('.mobile-click').addEventListener('click', function () {

            if (!document.body.classList.contains('action-btn-on')) {
                tl.to('.floating-icon ul li:not(:nth-last-child(1))', {
                    display: 'block',
                    opacity: 1,
                    y: 0
                })
                document.body.classList.add('action-btn-on')
            } else {
                tl.to('.floating-icon ul li:not(:nth-last-child(1))', {
                    y: '100%',
                    opacity: 0,
                    display: 'block',
                })
                document.body.classList.remove('action-btn-on')
            }

        });
    }, [])

    return (
        <StyledComponent className={'floating-icon'}>
            <ul>
                <li>
                    <a href="https://www.facebook.com/ranconrealestate/" target={'_blank'}>
                        <img src={'/images/static/s1.svg'}/>
                    </a>
                </li>
                {/*<li>*/}
                {/*    <a href="https://www.instagram.com/rancondevelopments/" target={'_blank'}>*/}
                {/*        <img src={'/images/static/s3.svg'}/>*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li>
                    <a href="https://www.linkedin.com/company/rrelbd/" target={'_blank'}>
                        <img src={'/images/static/s4.svg'}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@ranconrealestateltd.3438" target={'_blank'}>
                        <img src={'/images/static/s5.svg'}/>
                    </a>
                </li>





                <li className={'mobile-click'}><a>
                    <svg width="29.164" height="27.486" viewBox="0 0 29.164 27.486">
                        <path id="Path_10790" data-name="Path 10790"
                              d="M17.525,40.39A1.825,1.825,0,0,0,15.7,38.565H13.465a1.825,1.825,0,1,0,0,3.65H15.7a1.825,1.825,0,0,0,1.825-1.825ZM3.837,35.684a7.9,7.9,0,0,0,1.044.015,5.181,5.181,0,0,0,5.083,4.179h.7a2.864,2.864,0,0,0,0,1.025h-.7a6.206,6.206,0,0,1-6.127-5.219Zm-.522-1.091A3.977,3.977,0,0,1,0,30.672V28.248a3.977,3.977,0,0,1,3.977-3.977h.265a10.374,10.374,0,0,1,20.682,0h.265a3.977,3.977,0,0,1,3.977,3.977v2.423a3.977,3.977,0,0,1-3.977,3.977H24.014a.881.881,0,0,1-.881-.881V25.1a8.551,8.551,0,1,0-17.1,0v8.664a.881.881,0,0,1-.484.787,5.378,5.378,0,0,1-2.232.039Z"
                              transform="translate(0 -14.729)" fill="#08082b" fill-rule="evenodd"/>
                    </svg>

                </a></li>
            </ul>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: fixed;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999;
  mix-blend-mode: difference;

  ul {
    li {
      &:first-child{
        a{
          img{
            width: 8px;
          }
        }
      }
      a {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        //background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.12);
        //background-color: rgba(0, 0, 0, 0.05);
        //opacity: 0.5;
        //backdrop-filter: blur(5px);
        img {
          opacity: 1;
          width: 15px;
          z-index: 999999999;
        }
        svg {
          z-index: 9999999;
          position: relative;
          fill: white;
          width: 15px;
          // path, text {
          //   transition: all .4s ${ease};
          // }
        }

        &:after {
          position: absolute;
          content: '';
          height: 0%;
          width: 0%;
          border-radius: 50%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: all .6s ease;
        }

        &:hover {
          //&:after {
          //  width: 100%;
          //  height: 100%;
          //}
          //
          //path, text {
          //  fill: #FFF
          //}

        }
      }

      &:nth-of-type(1) a:after {
        background-color: #008FFF;
      }

      &:nth-of-type(2) a:after {
        background-color: #49C857;
      }

      &:nth-of-type(3) a:after {
        background-color: #EC1C24;
      }

      &:nth-of-type(4) a:after {
        background-color: ${text};
      }

      &:not(:nth-last-child(1)) {
        margin-bottom: 10px;
      }

      &.mobile-click {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {

    ul li {
      &:not(:nth-last-child(1)) {
        transform: translateY(100%);
        display: none;
        opacity: 0;
      }

      &.mobile-click {
        display: none;
      }
    }
  }

`;

export default MyComponent;
